import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Done({ t, setStep, step, registrationId, defaultValues, setColseModal }) {

  const navigate = useNavigate();

  useEffect(() => {
    if (!registrationId && !defaultValues?.id) {
      setStep(step - 1)
    }
  }, [registrationId, setStep, step, defaultValues]);

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <svg
          className="w-16 h-16 text-green-500 mx-auto mb-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          />
        </svg>
        <h2 className="text-2xl font-bold mb-2">{t('REGISRATION_COMPLETED')}</h2>
        <p className="text-gray-700 mb-4">{t("REGISTRATION_COMPLETED_MSG")}</p>
        {/* <button onClick={() => navigate("details", { state: { playerData: defaultValues } })} className="px-6 mx-5 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300">
          {t("VIEW_PLAYER_PROFILE")}
        </button> */}
        <button onClick={() => setColseModal(false)} className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300">
          {t("FINISH")}
        </button>
      </div>
    </div>
  );
}