import * as React from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GlobalModal from "./GlobalModal";
import PlayerCard from "../../constants/Svgs/PlayerCard";

const ControleLicences = ({ isOpen, setOpen, teamRecevant, teamVisiteuse }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPlayerIndex, setCurrentPlayerIndex] = React.useState(0);
  const [currentTeam, setCurrentTeam] = React.useState("teamRecevant");

  const playersRecevant = teamRecevant?.fullTeam || [];
  const playersVisiteuse = teamVisiteuse?.fullTeam || [];
  const allPlayers =
    currentTeam === "teamRecevant" ? playersRecevant : playersVisiteuse;

  const nextPlayer = () => {
    setCurrentPlayerIndex((prevIndex) =>
      prevIndex === allPlayers.length - 1 ? 0 : prevIndex + 1
    );
  };

  const previousPlayer = () => {
    setCurrentPlayerIndex((prevIndex) =>
      prevIndex === 0 ? allPlayers.length - 1 : prevIndex - 1
    );
  };

  const currentPlayer = allPlayers[currentPlayerIndex];

  return (
    <GlobalModal isOpen={isOpen} setOpen={setOpen}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "relative",
        }}
      >
        <button
          onClick={previousPlayer}
          style={{
            zIndex: 100,
          }}
        >
          <ArrowCircleLeftIcon
            style={{
              color: "white",
              fontSize: 46,
            }}
          />
        </button>
        <div
          style={{
            width: "40%",
            position: "relative",
          }}
        >
          <button
            onClick={() => setOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            <CloseIcon style={{ color: "white", fontSize: 30 }} />
          </button>
          <div
            className="flex items-center justify-center"
            style={{
              marginBottom: "-50px",
            }}
          >
            <div className="mx-2"
              style={{
                width: "25%",
                borderRadius: "10px",
              }}
            >
              <button
                onClick={() => {
                  setCurrentTeam("teamRecevant");
                  setCurrentPlayerIndex(0);
                }}
                style={{
                  zIndex: 100,
                  backgroundColor:
                    currentTeam === "teamRecevant" ? "blue" : "#f0f0f0",
                  marginRight: 10,
                  padding: 5,
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <div className="flex items-center justify-center space-x-2">
                  <Avatar
                    alt={teamRecevant.teamName}
                    src={teamRecevant.teamLogo}
                    className="w-10 h-10 rounded-full"
                  />
                  <span
                    style={{
                      color: currentTeam === "teamRecevant" ? "white" : "black",
                    }}
                    className="font-myteam font-semibold max-w-xs truncate"
                  >
                    {teamRecevant.teamName}
                  </span>
                </div>
              </button>
            </div>
            <div
              style={{
                width: "25%",
              }}
            >
              <button
                onClick={() => {
                  setCurrentTeam("teamVisiteuse");
                  setCurrentPlayerIndex(0);
                }}
                style={{
                  zIndex: 100,
                  backgroundColor:
                    currentTeam === "teamVisiteuse" ? "blue" : "#f0f0f0",
                  padding: 5,
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <div className="flex items-center justify-center space-x-2">
                  <span
                    style={{
                      color:
                        currentTeam === "teamVisiteuse" ? "white" : "black",
                    }}
                    className="font-myteam font-bold max-w-xs truncate"
                  >
                    {teamVisiteuse.teamName}
                  </span>
                  <Avatar
                    src={teamVisiteuse.teamLogo}
                    alt={teamVisiteuse.teamName}
                    className="w-10 h-10 rounded-full"
                  />
                </div>
              </button>
            </div>
          </div>
          {currentPlayer && (
            <PlayerCard style={{ width: "100%" }} player={currentPlayer} />
          )}
        </div>
        <button
          onClick={nextPlayer}
          style={{
            zIndex: 100,
          }}
        >
          <ArrowCircleRightIcon
            style={{
              color: "white",
              fontSize: 46,
            }}
          />
        </button>
      </div>
    </GlobalModal>
  );
};

export default ControleLicences;
