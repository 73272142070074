import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../contexts/AuthContext';
import { getLastResultStats } from '../../service/statisticService';
import { formatDateByDayName } from '../../utils/Utils';
import SmallLoading from '../../../../components/Loadings/SmallLoading';
import NoDataFound from '../Layouts/NoDataFound';

export default function LastResults({category}) {
    const { t } = useTranslation()
    const { user } = useContext(AuthContext)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getStats = async () => {
        try {
            const response = await getLastResultStats(category);
            setData(response.data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getStats()
    }, [category])
    return (
        <aside className="widget card widget--sidebar widget-results">
            <div className="widget__title card__header card__header--has-btn">
                <h4>{t('LATEST_RESULTS')}</h4>
            </div>
            {loading ? <div className="widget__content card__content my-10">
                <SmallLoading /></div> : !loading && (data?.length == 0 || !data) ? <div className='h-20'>
                    <NoDataFound />
                </div> :
                <div className="widget__content card__content">
                    <ul className="widget-results__list overflow-scroll" style={{ height: '28rem' }}>
                        {data?.filter(item => item?.status == 'played')?.length == 0 ? <div className='h-20'>
                            <NoDataFound />
                        </div>
                            : null}
                        {
                            data?.map((item, index) => {

                                const isUserTeamWinner =
                                    (user?.equipe_id === item.equipe_home_id && item.but_home > item.but_away) ||
                                    (user?.equipe_id === item.equipe_away_id && item.but_away > item.but_home)
                                if (item.status == 'played') {
                                    return (
                                        <li key={index} className="widget-results__item">
                                            <div className="widget-results__header justify-between">
                                                <div className="widget-results__title">{formatDateByDayName(new Date(`${item.match_date} ${item?.match_time}`))}</div>
                                                <div className="widget-results__title">{item?.Competition?.name}</div>
                                            </div>
                                            <div className="widget-results__content" style={{ backgroundColor: isUserTeamWinner ? '#0080004D' : '#F080804D' }}>
                                                <div className="widget-results__team widget-results__team--first">
                                                    <figure className="widget-results__team-logo">
                                                        <img src={item.equipeHome.image} alt={item.equipeHome.name} />
                                                    </figure>
                                                    <div className="widget-results__team-details">
                                                        <h5 className="widget-results__team-name">{item.equipeHome.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="widget-results__result">
                                                    <div className="widget-results__score">
                                                        <span className={`widget-results__score-${item.but_home > item.but_away ? 'winner' : 'loser'}`}>
                                                            {item.but_home}
                                                        </span>
                                                        {' - '}
                                                        <span className={`widget-results__score-${item.but_away > item.but_home ? 'winner' : 'loser'}`}>
                                                            {item.but_away}
                                                        </span>
                                                        <div className="widget-results__status">
                                                            {t('FINISHED')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget-results__team widget-results__team--second">
                                                    <figure className="widget-results__team-logo">
                                                        <img src={item.equipeAway.image} alt={item.equipeAway.name} />
                                                    </figure>
                                                    <div className="widget-results__team-details">
                                                        <h5 className="widget-results__team-name">{item.equipeAway.name}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                </div>}
        </aside>)
}
