import { ArrowUpward, Star } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function LineupTable({
    players,
    substitutes,
    team
}) {
    const { t } = useTranslation()
    return (
        <aside className="widget card card--has-table widget--sidebar widget-lineup-table">
            <div className="widget__title card__header">
                <div className='flex items-center'>
                    <img src={team?.image} alt={`${team?.name} `} className="w-8 h-8 mx-2 rounded-full" />
                    <h4 className='font-bold'> {team?.name}</h4>
                </div>
            </div>
            <div className="widget__content card__content">
                <div className="table-responsive">
                    <table className="table lineup-table">
                        <thead>
                            <tr>
                                <th className="lineup__num">{t("NBR")}</th>
                                <th className="lineup__pos">{t("POS")}</th>
                                <th className="lineup__name">{t("FULL_NAME")}</th>
                                <th className="lineup__info" />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                players?.map(player => (
                                    <tr key={player.id}>
                                        <td className="lineup__num">{player?.shirtNmbr || "-"}</td>
                                        <td className="lineup__pos">{player?.post || "-"}</td>
                                        {/* <td className="lineup__name flex items-center">
                                            <img src={player.image} alt={`${player.nom} ${player.prenom}`} className="w-8 h-8 mx-2 rounded-full" />
                                            {player.nom + " " + player.prenom} {player?.man_of_match?<p> MEN OF MATCH</p>:null}
                                        </td> */}

                                        <td className="lineup__name flex items-center space-x-2">
                                            <img
                                                src={player.image}
                                                alt={`${player.nom} ${player.prenom}`}
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <div className="flex items-center">
                                                <span>{player.nom} {player.prenom}</span>
                                                {player.man_of_match ? (
                                                    <div className="flex items-center ml-2" title="Man of the Match">
                                                        <Star className="w-5 h-5 text-yellow-400 fill-current" />
                                                    </div>
                                                ):null}
                                                {player?.capitain == 1 ? (
                                                    <div className="flex items-center ml-2" title="Captain">
                                                        <ArrowUpward className="w-5 h-5 text-blue-500" />
                                                    </div>
                                                ):null}
                                            </div>
                                        </td>
                                        <td className="lineup__info">
                                            <div className='flex items-center'>
                                                {Array.isArray(player?.carts) && player?.carts && player?.carts?.map((card, index) => (
                                                    <span className='mx-2'>
                                                        {card?.type == "red" && <img src='../../../../assets/images/footCards/red.png' width={30} height={30} />}
                                                        {card?.type == "yellow" && <img src='../../../../assets/images/footCards/yellow.png' width={30} height={30} />}
                                                        {card?.type == "yellowRed" && <img src='../../../../assets/images/footCards/yellowRed.png' width={30} height={30} />}
                                                        {card?.type == "Double_yellow_card" && <img src='../../../../assets/images/footCards/Double_yellow_card.png' width={30} height={30} />}
                                                        <p>{card.minute}'</p>
                                                    </span>
                                                ))}
                                                {!Array.isArray(player?.carts) && player?.carts && player?.carts != null &&
                                                    <span className='mx-2'>
                                                        {player?.carts?.type == "red" && <img src='../../../../assets/images/footCards/red.png' width={20} height={20} />}
                                                        {player?.carts?.type == "yellow" && <img src='../../../../assets/images/footCards/yellow.png' width={20} height={20} />}
                                                        {player?.carts?.type == "yellowRed" && <img src='../../../../assets/images/footCards/yellowRed.png' width={30} height={30} />}
                                                        {player?.carts?.type == "Double_yellow_card" && <img src='../../../../assets/images/footCards/Double_yellow_card.png' width={30} height={30} />}
                                                        <p>{player.carts.minute}'</p>
                                                    </span>
                                                }
                                                {player?.changements && player?.changements != null &&
                                                    <span className='mx-2'>
                                                        <i className="icon-svg icon-substitution" />
                                                        <p>{player?.changements?.minute}'</p>
                                                    </span>
                                                }
                                                {Array.isArray(player?.buts) && player?.buts && player?.buts?.length > 0 && (
                                                    player?.buts?.map((but) => {
                                                        return <span className='mx-2'>
                                                            {but.type == "regular" && <i className="icon-svg icon-soccer-ball" />}
                                                            {but.type == "penalty" && <i className="icon-svg icon-soccer-ball-penalty" />}
                                                            {but.type == "own" && <i className="icon-svg icon-soccer-ball-own-goal" />}
                                                            <p>{but?.minute}'</p>
                                                        </span>
                                                    })
                                                )}
                                                {Array.isArray(player?.assists) && player?.assists && player?.assists?.length > 0 && (
                                                    <span className='mx-2'>
                                                        <i className="icon-svg icon-soccer-assits" />
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <th colSpan={4} className="lineup__subheader">{t('SUBSTITUTE_PLAYERS')}</th>
                            </tr>
                            {
                                substitutes?.map(player => (
                                    <tr key={player.id}>
                                        <td className="lineup__num">{player?.shirtNmbr || "-"}</td>
                                        <td className="lineup__pos">{player?.post || "-"}</td>
                                        {/* <td className="lineup__name flex items-center">
                                            <img src={player.image} alt={`${player.nom} ${player.prenom}`} className="w-8 h-8 mx-2 rounded-full" />
                                            {player.nom + " " + player.prenom}
                                        </td> */}
                                        <td className="lineup__name flex items-center space-x-2">
                                            <img
                                                src={player.image}
                                                alt={`${player.nom} ${player.prenom}`}
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <div className="flex items-center">
                                                <span>{player.nom} {player.prenom}</span>
                                                {player.man_of_match ? (
                                                    <div className="flex items-center ml-2" title="Man of the Match">
                                                        <Star className="w-5 h-5 text-yellow-400 fill-current" />
                                                    </div>
                                                ):null}
                                                {player?.capitain == 1 ? (
                                                    <div className="flex items-center ml-2" title="Captain">
                                                        <ArrowUpward className="w-5 h-5 text-blue-500" />
                                                    </div>
                                                ):null}
                                            </div>
                                        </td>
                                        <td className="lineup__info">
                                            <div className='flex items-center'>
                                                {Array.isArray(player?.carts) && player?.carts && player?.carts?.map((card, index) => (
                                                    <span className='mx-2'>
                                                        {card?.type == "red" && <img src='../../../../assets/images/footCards/red.png' width={20} height={20} />}
                                                        {card?.type == "yellow" && <img src='../../../../assets/images/footCards/yellow.png' width={20} height={20} />}
                                                        {card?.type == "yellowRed" && <img src='../../../../assets/images/footCards/yellowRed.png' width={30} height={30} />}
                                                        {card?.type == "Double_yellow_card" && <img src='../../../../assets/images/footCards/Double_yellow_card.png' width={30} height={30} />}
                                                        <p>{card.minute}'</p>
                                                    </span>
                                                ))}
                                                {!Array.isArray(player?.carts) && player?.carts && player?.carts != null &&
                                                    <span className='mx-2'>
                                                        {player?.carts?.type == "red" && <img src='../../../../assets/images/footCards/red.png' width={20} height={20} />}
                                                        {player?.carts?.type == "yellow" && <img src='../../../../assets/images/footCards/yellow.png' width={20} height={20} />}
                                                        {player?.carts?.type == "yellowRed" && <img src='../../../../assets/images/footCards/yellowRed.png' width={30} height={30} />}
                                                        {player?.carts?.type == "Double_yellow_card" && <img src='../../../../assets/images/footCards/Double_yellow_card.png' width={30} height={30} />}
                                                        <p>{player.carts.minute}'</p>
                                                    </span>
                                                }
                                                {player?.changements && player?.changements != null &&
                                                    <span className='mx-2'>
                                                        <i className="icon-svg icon-substitution" />
                                                        <p>{player?.changements?.minute}'</p>
                                                    </span>
                                                }
                                                {Array.isArray(player?.buts) && player?.buts && player?.buts?.length > 0 && (
                                                    player?.buts?.map((but) => {
                                                        // console.log(but)
                                                        return <span className='mx-2'>
                                                            {but.type == "regular" && <i className="icon-svg icon-soccer-ball" />}
                                                            {but.type == "penalty" && <i className="icon-svg icon-soccer-ball-penalty" />}
                                                            {but.type == "own" && <i className="icon-svg icon-soccer-ball-own-goal" />}
                                                            <p>{but?.minute}'</p>
                                                        </span>
                                                    })
                                                )}
                                                {Array.isArray(player?.assists) && player?.assists && player?.assists?.length > 0 && (
                                                    <span className='mx-2'>
                                                        <i className="icon-svg icon-soccer-assits" />
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>
            </div>
        </aside>
    )
}
