import { Avatar } from "@mui/material";
import React from "react";

export default function MatchSelected({ match,t}) {

  return (
    <div className="text-center items-center">
      <div className="flex items-center justify-center space-x-2">
        <Avatar
          alt={match?.match?.equipeAway.name}
          src={match?.match?.equipeAway.image}
          className="w-10 h-10 rounded-full"
        />
        <span className="font-myteam font-bold text-gray-700 max-w-xs truncate">
        {match?.match?.equipeAway.name}
        </span>
        <span className="mx-2 font-myteam font-bold">{t("VS")}</span>
        <span className="font-myteam font-bold text-gray-700 max-w-xs truncate">
          {match?.match?.equipeHome.name}
        </span>
        <Avatar
          alt={match?.match?.equipeHome.name}
          src={match?.match?.equipeHome.image}
          className="w-10 h-10 rounded-full"
        />
      </div>
      <div className=" font-myteam text-gray-500 text-sm">
        {match?.match?.match_date} {match?.match?.match_time} / {match?.match?.location}
      </div>
    </div>
  );
}
