import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Card, CardContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const MatchModal = ({
  open,
  handleClose,
  title,
  Available,
  RowSelected 
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchHasArtbiter = useSelector((state) => state.matchReducer?.matchHasArtbiter);

  const handlePrepareClick = () => {
    navigate("/match/prepare", { state: { match: RowSelected } });
  };

  const handleMatchSheetClick = () => {
    !matchHasArtbiter
      ? navigate("/match/events", { state: { match: RowSelected } })
      : navigate("/match/fuille", { state: { match: RowSelected } });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h6"
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",
            color: "black",
            marginBottom: "16px",
          }}
        >
          {title}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            className={Available === 0 ? "cursor-pointer font-myteam" : ""}
            item
            xs={6}
            md={6}
            onClick={Available === 0 ? handlePrepareClick : undefined}
          >
            <Card>
              <CardContent
                align="center"
                style={
                  Available === 0
                    ? {
                        backgroundColor: "#074173",
                        color: "white",
                        padding: "16px",
                        borderRadius: "5px",
                      }
                    : {
                        backgroundColor: "#EEEEEE",
                        color: "gray",
                        padding: "16px",
                        borderRadius: "5px",
                      }
                }
              >
                <DriveFileRenameOutlineIcon fontSize="large" />
                <Typography variant="body1">{t("PREPARER_TEAM")}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            className={Available === 1 ? "cursor-pointer font-myteam" : ""}
            item
            xs={6}
            md={6}
            onClick={Available === 1 ? handleMatchSheetClick : undefined}
          >
            <Card>
              <CardContent
                align="center"
                style={
                  Available === 1
                    ? {
                        backgroundColor: "#074173",
                        color: "white",
                        padding: "16px",
                        borderRadius: "5px",
                      }
                    : {
                        backgroundColor: "#EEEEEE",
                        color: "gray",
                        padding: "16px",
                        borderRadius: "5px",
                      }
                }
              >
                <DescriptionIcon fontSize="large" />
                <Typography variant="body1" className="font-myteam">
                  {t("FEUILLE_DE_MATCH")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MatchModal;
