import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { divisionsData } from '../../utils/selectesData';
import { getTeam, getTeamByCategory } from '../../../../api/services/matchServices';
import SmallLoading from '../../../../components/Loadings/SmallLoading';

export default function ModalSurClassement({ setPlayers, categActive, modal20, setModal20 }) {
    const { t } = useTranslation();
    const [Loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState([]);

    const [playerOptions, setPlayerOptions] = useState([]);

    async function fetchPlayers(category) {
        const players = await getTeamByCategory(category)
        setPlayerOptions(players?.Players?.map(player => ({
            value: player,
            label: `${player.nom} ${player.prenom}`,
            avatar: player.image
        })));
        setLoading(false);
    }

    useEffect(() => {
        if (selectedCategory) {
            setLoading(true);
            fetchPlayers(selectedCategory?.value)
        }
    }, [selectedCategory]);

    const handleCategoryChange = (selectedOption) => {
        selectedOption?.value != categActive ?
            setSelectedCategory(selectedOption) : setSelectedCategory(null)
    };

    const handlePlayerChange = (selectedOptions) => {
        setSelectedPlayers(selectedOptions);
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#e2e8f0',
            borderRadius: '16px',
            padding: '2px',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontSize: '14px',
            padding: '2px 8px',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            borderRadius: '50%',
            padding: '2px',
        }),
    };

    const formatOptionLabel = ({ avatar, label }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={avatar} alt={label} style={{ marginRight: '10px', width: '32px', height: '32px', borderRadius: '50%' }} />
            <span>{label}</span>
        </div>
    );

    const handleConvocPlayer = () => {
        setPlayers((prevState) => {
            let updatedPlayers = [...prevState];
            selectedPlayers?.forEach((selectedPlayer) => {
                const playerExists = prevState?.some(
                    (statePlayer) => statePlayer.id === selectedPlayer.value.id
                );
                if (!playerExists) {
                    updatedPlayers.push(selectedPlayer.value);
                }
            });

            return updatedPlayers;
        });
        setModal20(false);
    }

    return (
        <Transition appear show={modal20} as={Fragment}>
            <Dialog as="div" open={modal20} onClose={() => { setModal20(false) }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 py-8">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel border-0 p-0 rounded-lg w-full max-w-md text-black dark:text-white-dark bg-white dark:bg-gray-800 shadow-xl">
                                <div className="flex bg-gray-50 dark:bg-gray-700 items-center justify-between px-5 py-3 border-b border-gray-200 dark:border-gray-600">
                                    <h5 className="font-bold text-lg">
                                        {t("SURCLASSEMENT")}
                                    </h5>
                                    <button onClick={() => { setModal20(false) }} type="button" className="text-gray-400 hover:text-gray-500 focus:outline-none">
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="p-5">
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            {t("SELECT_CATEGORY_AGE")}
                                        </label>
                                        <Select
                                            options={divisionsData}
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            placeholder={t("SELECT_CATEGORY")}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    {
                                        Loading ? <SmallLoading /> :
                                            selectedCategory &&
                                            <div className="mb-4">
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                                    {t("SELECT_PLAYERS")}
                                                </label>
                                                {
                                                    playerOptions.length === 0 ?
                                                        <div className="mt-4 p-4 border border-yellow-400 bg-yellow-50 rounded-md">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                                    </svg>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-yellow-800">
                                                                        {t('NO_PLAYERS_AVAILABLE')}
                                                                    </h3>
                                                                    <div className="mt-2 text-sm text-yellow-700">
                                                                        <p>
                                                                            {t('ADDPLAYERS_TO_ROSTER')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <Select
                                                            options={playerOptions}
                                                            isMulti
                                                            value={selectedPlayers}
                                                            onChange={handlePlayerChange}
                                                            placeholder={t("SELECT_PLAYERS")}
                                                            formatOptionLabel={formatOptionLabel}
                                                            styles={customStyles}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                        />
                                                }
                                            </div>
                                    }
                                    <div className="flex justify-between space-x-2 mt-6">
                                        <button
                                            type="button"
                                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md text-sm font-medium hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                            onClick={() => { setModal20(false) }}
                                        >
                                            {t("CANCEL")}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleConvocPlayer()}
                                            className="px-4 py-2 bg-green-700 text-white disabled:bg-gray-200 disabled:text-black disabled:text-sm rounded-md text-sm font-medium hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                            disabled={!selectedCategory || selectedPlayers.length === 0}
                                        >
                                            {t("CONFIRM_SURCLASSEMENT")}
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}