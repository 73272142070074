import React from "react";
import { useSelector } from "react-redux";

const NumberBar = ({ team1, team2 }) => {
  const events = useSelector((state) => state.matchEventsReducer.events);

  const renderIcon = (event,index) => {
    switch (event.eventName) {
      case "subs":
        return (
          <div
          key={index}
            className={`game-timeline__event game-timeline__event--${event.RegularTime}`}
          >
            {event?.team?.id == team1?.id && (
              <>
                <div className="game-timeline__team-1">
                  <div className="game-timeline__event-info">
                    <div className="game-timeline__event-name">
                      {event?.playerIn?.firstName}
                    </div>
                    <div className="game-timeline__event-name">
                      {event?.playerOut?.firstName}
                    </div>
                  </div>
                  <i className="icon-svg icon-substitution" />
                </div>
                <div className="game-timeline__time">{event.RegularTime}</div>
              </>
            )}
            {event?.team?.id == team2?.id && (
              <>
                <div className="game-timeline__time">{event.RegularTime}</div>
                <div className="game-timeline__team-2">
                  <i className="icon-svg icon-substitution" />
                  <div className="game-timeline__event-info">
                    <div className="game-timeline__event-name">
                      {event?.playerIn?.firstName}
                    </div>
                    <div className="game-timeline__event-name">
                      {event?.playerOut?.firstName}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case "goalScorer":
        return (
          <div
          key={index}
            className={`game-timeline__event game-timeline__event--${event.RegularTime}`}
          >
            {event?.team?.id == team1?.id && (
              <>
                <div className="game-timeline__team-1">
                  <div className="game-timeline__event-info">
                    <div className="game-timeline__event-name">
                      {event?.goalScorer?.firstName}
                    </div>
                  </div>
                  <i className="icon-svg icon-soccer-ball" />
                </div>
                <div className="game-timeline__time">{event.RegularTime}</div>
              </>
            )}
            {event?.team?.id == team2?.id && (
              <>
                <div className="game-timeline__time">{event.RegularTime}</div>
                <div className="game-timeline__team-2">
                  <i className="icon-svg icon-soccer-ball" />
                  <div className="game-timeline__event-info">
                    <div className="game-timeline__event-name">
                      {event?.goalScorer?.firstName}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case "discipline":
        return (
          <div
          key={index}
            className={`game-timeline__event game-timeline__event--${event.RegularTime}`}
          >
            {event?.team?.id == team1?.id && (
              <>
                <div className="game-timeline__team-1 flex flex-col items-center">
                  <div className="game-timeline__event-info">
                    <div className="game-timeline__event-name">
                      {event.pers[0].firstName}
                    </div>
                  </div>
                  <img
                    src={`../../assets/images/footCards/${event?.cardDiscipline}.png`}
                    className="w-4 h-6 flex justify-center"
                    alt="Red card"
                  />
                </div>
                <div className="game-timeline__time">{event.RegularTime}</div>
              </>
            )}
            {event?.team?.id == team2?.id && (
              <>
                <div className="game-timeline__time">{event.RegularTime}</div>
                <div className="game-timeline__team-2 flex flex-col items-center">
                  <img
                    src={`../../assets/images/footCards/${event?.cardDiscipline}.png`}
                    className="w-4 h-6 flex justify-center"
                    alt="Red card"
                  />
                  <div className="game-timeline__event-info">
                    <div className="game-timeline__event-name">
                      {event.pers[0].firstName}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-4 card__content--pattern-dotted">
      <div className="game-timeline-wrapper">
        <div className="game-timeline game-timeline--adaptive">
          <div className="game-timeline__event">
            <div className="game-timeline__team-1">
              <div className="game-timeline__team-shirt">
                <img src={team1?.image} className="rounded w-30 h-8" />
              </div>
            </div>
            <div className="game-timeline__time">0’</div>
            <div className="game-timeline__team-2">
              <div className="game-timeline__team-shirt">
                <img src={team2?.image} className="rounded w-30 h-8" />
              </div>
            </div>
          </div>

          {events?.map((event,index) => renderIcon(event,index))}
          <div className="game-timeline__event game-timeline__event--ht">
            <div className="game-timeline__time">HT</div>
          </div>
          {/* <div className="game-timeline__event game-timeline__event--68">
            <div className="game-timeline__team-1">
              <div className="game-timeline__event-info">
                <div className="game-timeline__event-name">C. Grass (P)</div>
                <div className="game-timeline__event-desc">Alchemists 2-0</div>
              </div>
              <i className="icon-svg icon-soccer-ball-penalty" />
            </div>
            <div className="game-timeline__time">68’</div>
          </div> */}

          <div className="game-timeline__event game-timeline__event--ft">
            <div className="game-timeline__time">FT</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberBar;