import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StickyHeadTable from "../components/Tables/StickyHeadTable";
import { useSelector } from "react-redux";
import { Avatar, TextField, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loadings/Loading";
import BasicTabs from "../components/layouts/BasicTabs";
import MatchEvent from "../components/Forms/Events/MatchEvent";
import DisciplineEvent from "../components/Forms/Events/DisciplineEvent";
import SubsEvent from "../components/Forms/Events/SubsEvent";
import GoalsEvent from "../components/Forms/Events/GoalsEvent";
import InjuriesEvent from "../components/Forms/Events/InjuriesEvent";
import NumberBar from "../components/Forms/Events/NumberBar";
import HistoryEvent from "../components/Forms/Events/HistoryEvent";
import MatchScoore from "../components/layouts/MatchScoore";
import { getTeamById } from "../redux/selectors/matchSelectors";
import ModalButton from "../components/modals/ModalButton";
import SignatureModal from "../components/modals/SignatureModal";
import { AuthContext } from "../contexts/AuthContext";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import GoBackButton from "../components/GoBackButton";
import { fetchTeam, getComposition } from "../redux/middleware/matchThunks";
import { useDispatch } from "react-redux";
import matchEventService from "../api/services/matchEventService";
import { useSnackbar } from "notistack";
import { MobileContext } from "../backoffice/src/context/isMobileContext";

export default function MatchEventsPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { match } = location.state || {};
  const dispatch = useDispatch();
  const matchHasArtbiter = useSelector(
    (state) => state.matchReducer.matchHasArtbiter
  );
  const loading = useSelector((state) => state.matchReducer.loading);
  const error = useSelector((state) => state.matchReducer.error);
  const [filter, setFilter] = useState("");
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");
  const teams = useSelector((state) => state.matchReducer.teams);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [currentTeam, setCurrentTeam] = useState("teamVisiteuse");
  const [teamRecevant, setTeamRecevant] = useState([]);
  const [teamVisiteuse, setTeamVisieuse] = useState([]);
  const [playerSelected, setPlayerSelected] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tirsAuBut, setTirsAuBut] = useState(false);
  const isMobile = useContext(MobileContext);
  const [Scoore, setScoore] = useState({
    equipeAwayScoore: 0,
    equipeHomeScoore: 0,
  });
  const [signatures, setSignatures] = useState({
    teamRecevant: false,
    arbiture: false,
    teamVisiteuse: false,
  });
  const [prolongations, setProlongations] = useState(false);
  const events = useSelector((state) => state.matchEventsReducer.events);
  const navigate = useNavigate();
  const [penaltyHomeScore, setPenaltyHomeScore] = useState(0);
  const [penaltyAwayScore, setPenaltyAwayScore] = useState(0);
  const [openSignateurAfterMarch, setOpenSignateurAfterMarch] = useState(false);
  const [openSelectPlayerEvent, setOpenSelectPlayerEvent] = useState(false);

  useEffect(() => {
    if (!matchHasArtbiter) {
      dispatch(fetchTeam(match?.match?.id));
      dispatch(getComposition(match?.match?.id, user?.equipe_id, "teams"));
    }

    if (!match || match == undefined || match == null) {
      navigate("/");
    }
  }, []);
  const renderIcon = (event) => {
    switch (event.eventName) {
      case "subs":
        return (
          <div className="flex flex-wrap items-center ">
            <img
              src={`../../assets/images/subs.png`}
              className="w-10 h-5 absolute"
              alt="subs"
            />
          </div>
        );
      case "goalScorer":
        return (
          <div className="flex flex-wrap items-center ">
            <div className="flex items-center p-2 relative">
              <SportsSoccerIcon />
            </div>
          </div>
        );
      case "discipline":
        return (
          <div className="flex flex-wrap items-center ">
            <div className="flex items-center p-2 relative">
              <img
                src={`../../assets/images/footCards/${event?.cardDiscipline}.png`}
                className="w-15 h-5 absolute"
                alt="Red card"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  const columns = [
    {
      id: "fullName",
      label: t("FULL_NAME"),
      minWidth: 100,
      format: (value, row) => (
        <div className="flex items-center space-x-2">
          <div className="relative">
            <Avatar
              alt={value}
              src={row.image}
              className="w-10 h-10 rounded-full"
            />
            <div className="absolute top-0 right-0">
              {row?.events?.length != 0
                ? row?.events.map(
                    (evnt) =>
                      evnt?.eventName == "discipline" && renderIcon(evnt)
                  )
                : null}
            </div>
          </div>
          <span className="font-medium font-myteam text-gray-700">{value}</span>
        </div>
      ),
    },
    { id: "role", label: t("ROLE_FUNCTION"), minWidth: 100 },
    { id: "shirtNmbr", label: t("SHIRT_NUMBER"), minWidth: 100 },
    {
      id: "licenceNumber",
      label: t("LICENCE_NUMBER"),
      minWidth: 170,
    },
  ];

  useEffect(() => {
    setTeamRecevant(getTeamById(teams, match?.match?.equipeHome.id));
    setTeamVisieuse(getTeamById(teams, match?.match?.equipeAway.id));
    setCurrentTeam(
      !matchHasArtbiter && teamVisiteuse?.id == user?.equipe_id
        ? "teamVisiteuse"
        : "teamRecevant"
    );
  }, [teamRecevant, teamVisiteuse, teams]);

  useEffect(() => {
    const allPlayersAndStaffs =
      currentTeam === "teamRecevant"
        ? [...(teamRecevant?.team ?? [])]
        : [...(teamVisiteuse?.team ?? [])];
    setFilteredPlayers(
      allPlayersAndStaffs.filter((eqp) => {
        const filterWords = filter.trim().toLowerCase().split(/\s+/);
        const fullName = `${eqp.nom.toLowerCase()} ${eqp.prenom.toLowerCase()}`;
        return filterWords.every((word) => fullName.includes(word));
      })
    );
  }, [filter, currentTeam, teams, teamRecevant, teamVisiteuse]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredPlayersByRole = selectedRoleFilter
    ? filteredPlayers.filter((eqp) => eqp.role === selectedRoleFilter)
    : filteredPlayers;

  const rows = filteredPlayersByRole?.map((eqp) => ({
    id: eqp.id,
    image: eqp.image,
    firstName: eqp.nom,
    isCaptain: eqp.isCaptain || false,
    lastName: eqp.prenom,
    teamId:
      currentTeam === "teamRecevant" ? teamRecevant?.id : teamVisiteuse?.id,
    fullName: `${eqp.nom} ${eqp.prenom}`,
    role: eqp.role,
    licenceNumber: eqp.licenceNumber || eqp?.licenceId || "-",
    shirtNmbr: eqp.shirtNumber || eqp?.shirtNmbr || "-",
    position_index: eqp.position_index,
    isTitulaire: eqp.isTitulaire,
    post: eqp.post,
    playerSelected: playerSelected?.some((p) => p?.id === eqp?.id),
    events: events?.filter(
      (event) => event.pers && event.pers[0]?.id === eqp?.id
    ),
  }));

  const handlePlayerClick = (user) => {
    setPlayerSelected((prevState) => {
      const isSelected = prevState.some((p) => p?.id === user?.id);
      const isPlayerRole = user?.role?.toLowerCase() === "player";

      if (isSelected) {
        return prevState.filter((p) => p?.id !== user.id);
      } else {
        if (
          user?.events?.some(
            (e) =>
              e.eventName === "discipline" &&
              (e?.cardDiscipline === "red" ||
                e?.cardDiscipline === "yelloRed" ||
                e?.cardDiscipline === "Double_yellow_card")
          )
        ) {
          return [];
        } else if (activeTab === 0) {
          return [];
        } else if (activeTab === 1) {
          return [user];
        } else if (activeTab === 4 && isPlayerRole) {
          return [user];
        } else if ((activeTab === 2 || activeTab === 3) && isPlayerRole) {
          if (prevState.length < 2) {
            return [...prevState, user];
          } else {
            return prevState;
          }
        } else {
          return prevState;
        }
      }
    });
  };

  const tabs = [
    {
      label: t("MATCH"),
      content: (
        <MatchEvent
          prolongations={prolongations}
          setProlongations={setProlongations}
          t={t}
          match={match}
          Scoore={Scoore}
          setScoore={setScoore}
          currentTeam={currentTeam}
          tirsAuBut={tirsAuBut}
          setTirsAuBut={setTirsAuBut}
          teamRecevant={teamRecevant}
          teamVisiteuse={teamVisiteuse}
          penaltyHomeScore={penaltyHomeScore}
          penaltyAwayScore={penaltyAwayScore}
          setPenaltyHomeScore={setPenaltyHomeScore}
          setPenaltyAwayScore={setPenaltyAwayScore}
          players={rows?.filter((row) => row?.role?.toLowerCase() === "player")}
        />
      ),
    },
    {
      label: t("DISCIPLINE"),
      content: (
        <DisciplineEvent
          t={t}
          playerSelected={playerSelected}
          setPlayerSelected={setPlayerSelected}
          currentTeam={currentTeam}
          teamRecevant={teamRecevant}
          teamVisiteuse={teamVisiteuse}
          players={rows?.filter((row) => row?.role?.toLowerCase() === "player")}
        />
      ),
    },
    {
      label: t("REMPLACANTS"),
      content: (
        <SubsEvent
          t={t}
          playerOut={playerSelected[0]}
          playerIn={playerSelected[1]}
          setPlayerSelected={setPlayerSelected}
          currentTeam={currentTeam}
          teamRecevant={teamRecevant}
          teamVisiteuse={teamVisiteuse}
          players={rows?.filter((row) => row?.role?.toLowerCase() === "player")}
        />
      ),
    },
    {
      label: t("GOALS"),
      content: (
        <GoalsEvent
          t={t}
          goalScorer={playerSelected[0]}
          assistPlayer={playerSelected[1]}
          setPlayerSelected={setPlayerSelected}
          currentTeam={currentTeam}
          teamRecevant={teamRecevant}
          teamVisiteuse={teamVisiteuse}
          Scoore={Scoore}
          match={match}
          setScoore={setScoore}
          players={rows?.filter((row) => row?.role?.toLowerCase() === "player")}
        />
      ),
    },
    {
      label: t("INJURYS"),
      content: (
        <InjuriesEvent
          t={t}
          injuredPlayer={playerSelected[0]}
          setPlayerSelected={setPlayerSelected}
          currentTeam={currentTeam}
          teamRecevant={teamRecevant}
          teamVisiteuse={teamVisiteuse}
          players={rows?.filter((row) => row?.role?.toLowerCase() === "player")}
        />
      ),
    },
    {
      label: t("EVENTS"),
      content: <HistoryEvent title={"MATCH_EVENTS"} />,
    },
  ];

  const uniqueRoles = [
    ...new Set(
      currentTeam == "teamRecevant"
        ? [...(teamRecevant?.team?.map((row) => row.role) ?? [])]
        : [...(teamVisiteuse?.team?.map((row) => row.role) ?? [])]
    ),
  ];

  const handleRoleFilterChange = (e) => {
    setSelectedRoleFilter(e.target.value);
  };

  const handleTabChange = (tab) => {
    setPlayerSelected([]);
    setActiveTab(tab);
  };

  const handleSubmit = async () => {
    const getPeriod = (minute, extraTime) => {
      if (minute <= 45 || (minute === 45 && extraTime > 0)) {
        return "1";
      } else {
        return "2";
      }
    };

    const matchStatsEvent = events.find(
      (event) => event.eventName === "match_stats"
    );

    const matchEventData = {
      match: match?.id,
      equipe_id: user?.equipe_id,
      carts: events
        .filter((event) => event.eventName === "discipline")
        .map((event) => ({
          type: event.cardDiscipline,
          minute: event.RegularTime,
          period: getPeriod(event.RegularTime, event.extraTime),
          extra_time: event.extraTime,
          joueur_id: event.pers[0].id,
          description: event.description || "",
          motif_id: event.Motif,
          equipe: event?.team.id,
        })),
      blessures: events
        .filter((event) => event.eventName === "injury")
        .map((event) => ({
          joueur_id: event.injuredPlayer.id,
          minute: event.RegularTime,
          period: getPeriod(event.RegularTime, event.extraTime),
          extra_time: event.extraTime,
          equipe: event?.team.id,
          description: "",
          reason: event?.reason,
          position: event?.position,
        })),
      changements: events
        .filter((event) => event.eventName === "subs")
        .map((event) => ({
          joueur_id_in: event?.playerIn.id,
          joueur_id_out: event?.playerOut.id,
          minute: event?.RegularTime,
          period: getPeriod(event?.RegularTime, event.extraTime),
          extra_time: event?.extraTime,
          equipe: event?.team.id,
        })),
      buts: events
        .filter((event) => event.eventName === "goalScorer")
        .map((event) => ({
          joueur_id: event.goalScorer?.id,
          joueur_assist_id: event.assistPlayer?.id,
          minute: event.RegularTime,
          period: getPeriod(event.RegularTime, event.extraTime),
          extra_time: event.extraTime,
          equipe: event?.team.id,
          type: event.type,
        })),
      man_of_match: matchStatsEvent ? matchStatsEvent?.man_of_match?.id : null,
      status: matchStatsEvent ? matchStatsEvent.status : {},
      match_stats: matchStatsEvent ? matchStatsEvent.match_stats : {},
    };

    try {
      await matchEventService.addEvent(matchEventData);
      const variant = "success";
      enqueueSnackbar(t("MATCH_FORM_SENT"), { variant });
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <MatchScoore
            match={match}
            equipeAwayScoore={Scoore?.equipeAwayScoore}
            equipeHomeScoore={Scoore?.equipeHomeScoore}
            penaltyHomeScore={penaltyHomeScore}
            penaltyAwayScore={penaltyAwayScore}
            MatchWithPenaltys={tirsAuBut}
          />
          <div
            className={`mt-4 ${
              isMobile ? "p-0" : "p-4"
            } bg-white shadow-lg rounded-lg`}
          >
            <div className={`${isMobile ? "p-2" : ""}`}>{<GoBackButton />}</div>
            {!isMobile ? (
              <NumberBar
                prolongations={prolongations}
                team1={match?.match?.equipeAway}
                team2={match?.match?.equipeHome}
              />
            ) : null}
            <div className="flex max-sm:flex-wrap mt-4 justify-between">
              {isMobile ? null : (
                <div
                  className={`max-sm:w-full w-1/2 mb-4 ${
                    isMobile ? "px-0" : "px-4"
                  }`}
                >
                  <div className="flex items-center pb-5">
                    {!matchHasArtbiter &&
                      teamRecevant?.id == user?.equipe_id &&
                      teamRecevant && (
                        <button
                          onClick={() => {
                            setCurrentTeam("teamRecevant");
                          }}
                          style={{
                            zIndex: 100,
                            backgroundColor:
                              currentTeam === "teamRecevant"
                                ? "blue"
                                : "#f0f0f0",
                            marginRight: 10,
                            padding: 5,
                          }}
                        >
                          <div className="flex items-center justify-center space-x-2">
                            <Avatar
                              alt={teamRecevant?.name}
                              src={teamRecevant?.logo}
                              className="w-10 h-10 rounded-full"
                            />
                            <span
                              style={{
                                color:
                                  currentTeam === "teamRecevant"
                                    ? "white"
                                    : "black",
                              }}
                              className="font-myteam font-semibold max-w-xs truncate"
                            >
                              {teamRecevant?.name}
                            </span>
                          </div>
                        </button>
                      )}
                    {!matchHasArtbiter &&
                      teamVisiteuse?.id == user?.equipe_id &&
                      teamVisiteuse && (
                        <button
                          onClick={() => {
                            setCurrentTeam("teamVisiteuse");
                          }}
                          style={{
                            zIndex: 100,
                            backgroundColor:
                              currentTeam === "teamVisiteuse"
                                ? "blue"
                                : "#f0f0f0",
                            padding: 5,
                          }}
                        >
                          <div className="flex items-center justify-center space-x-2">
                            <span
                              style={{
                                color:
                                  currentTeam === "teamVisiteuse"
                                    ? "white"
                                    : "black",
                              }}
                              className="font-myteam font-bold max-w-xs truncate"
                            >
                              {teamVisiteuse?.name}
                            </span>
                            <Avatar
                              src={teamVisiteuse?.logo}
                              alt={teamVisiteuse?.name}
                              className="w-10 h-10 rounded-full"
                            />
                          </div>
                        </button>
                      )}
                  </div>
                  <div className="flex">
                    <TextField
                      label={t("SEARCH_BY_NAME")}
                      value={filter}
                      onChange={handleFilterChange}
                      variant="outlined"
                      className="mb-4 mx-2 w-1/2 h-16"
                    />
                    <Select
                      value={selectedRoleFilter}
                      onChange={handleRoleFilterChange}
                      displayEmpty
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      className="mb-4 mx-2 w-1/2 h-3/5"
                      variant="outlined"
                    >
                      <MenuItem value="" className="font-myteam">
                        {t("ALL_ROLES")}
                      </MenuItem>
                      {uniqueRoles.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <StickyHeadTable
                    loading={loading}
                    columns={columns}
                    rows={rows}
                    error={error}
                    cursor={"cursor-pointer"}
                    headerColor={"black"}
                    headerLabelsColor={"white"}
                    initialRowsPerPage={25}
                    onRowClick={handlePlayerClick}
                    rowActive={2}
                  />
                </div>
              )}
              <div className="max-sm:w-full w-1/2 flex mb-4">
                <div className="w-full font-myteam h-full max-sm:w-full">
                  <BasicTabs
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    activeTab={activeTab}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex flex-wrap mb-2 ${
                isMobile
                  ? "w-auto justify-center mt-0 "
                  : " mt-2 justify-between "
              }`}
            >
              {matchHasArtbiter ? (
                <>
                  <div className="flex flex-wrap">
                    <ModalButton
                      onClick={() =>
                        navigate("/match/reserve", {
                          state: { match, type: "after" },
                        })
                      }
                      text={t("RESERVES_APRES_MATCH")}
                      className="bg-blue-800 uppercase hover:bg-blue-500"
                    />
                  </div>
                  <ModalButton
                    onClick={() => setOpenSignateurAfterMarch(true)}
                    text={t("SIGNATURES_APRES_MATCH")}
                    className="bg-orange-500 mx-5 uppercase hover:bg-orange-700 justify-self-end"
                  />
                </>
              ) : (
                <div className="flex flex-wrap p-2">
                  <ModalButton
                    onClick={handleSubmit}
                    text={t("SEND_MATCH_SHEET")}
                    className="bg-green-800 uppercase hover:bg-green-500 disabled:bg-blue-500"
                    disabled={
                      events?.length == 0 ||
                      !events.some(
                        (event) =>
                          event.eventName === "match_stats" && event?.status
                      )
                    }
                  />
                </div>
              )}

              <SignatureModal
                type={"after"}
                isArbiture={user}
                teamRecevant={teamRecevant}
                teamVisiteuse={teamVisiteuse}
                isOpen={openSignateurAfterMarch}
                matchId={match?.match?.id}
                setOpen={setOpenSignateurAfterMarch}
                t={t}
                signatures={signatures}
                setSignatures={setSignatures}
                match={match}
                equipeAwayScoore={Scoore?.equipeAwayScoore}
                equipeHomeScoore={Scoore?.equipeHomeScoore}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
