import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Check, Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const PlayerCard = ({ player, onRemove, onAdd }) => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      className="flex items-center px-2 space-x-3 w-full transition-shadow duration-300 hover:shadow-md"
    >
      <div className="relative">
        <Avatar
          alt={player.nom}
          src={player.image}
          className="w-16 h-16 border-2 border-gray-200"
        />
        {player.shirtNmbr ? (
          <div className="absolute -bottom-1 -right-1 bg-blue-500 text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center">
            {player.shirtNmbr}
          </div>
        ):null}
      </div>
      <CardContent className="flex-1 py-2 px-0">
        <Typography variant="subtitle1" className="font-semibold text-gray-800">
          {player.fullName
            ? player.fullName
            : `${player?.nom} ${player?.prenom}`}
        </Typography>
        <Typography variant="body2" className="text-gray-500">
          {player.role} {player.post ? `(${player.post})`:null}
        </Typography>
      </CardContent>
      <Tooltip title={onRemove ? t("Remove player") : t("Add player")} arrow>
        <IconButton
          onClick={() => (onRemove ? onRemove(player) : onAdd(player))}
          className={`${
            onRemove
              ? "text-red-500 hover:bg-red-50"
              : "text-blue-500 hover:bg-blue-50"
          } transition-colors duration-300`}
          size="small"
        >
          {onRemove ? <CloseIcon /> : <Check />}
        </IconButton>
      </Tooltip>
    </Card>
  );
};

export default PlayerCard;
