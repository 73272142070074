import React, { useState, Fragment, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export default function ModalSelectPlayerMobile({ players, callback, isOpen, onClose, title, avaliableData, messageForAdd }) {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const handleSelectPlayer = (player) => {
    setSelectedPlayer(player);
    callback(player);
    onClose();
  };

  const filteredPlayers = useMemo(() => {
    return players.filter(player =>
      player.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [players, searchTerm]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-6 text-gray-900 flex justify-between items-center mb-4"
                >
                  {title||t('SELECT_PLAYER')}
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                  >
                    x
                  </button>
                </Dialog.Title>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder={t('SEARCH_PLAYER')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-3 border bg-white border-gray-300 rounded-md mb-4 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                  />
                  {filteredPlayers.length > 0 ? (
                    <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
                      {filteredPlayers.map((player) => (
                        <li key={player.id} className="py-3">
                          <button
                            onClick={() => handleSelectPlayer(player)}
                            disabled={player?.convoced}
                            style={{ opacity: player?.convoced ? '0.5' : '100' }}
                            className="w-full text-left hover:bg-blue-50 p-3 rounded transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <div className="flex items-center">
                              <img src={player.image} alt={player.fullName} className="w-12 h-12 rounded-full mr-4 border-2 border-blue-200" />
                              <div>
                                <p className="font-semibold text-gray-800">{player.fullName}</p>
                                <p className="text-sm text-gray-500">{player.licenceNumber}</p>
                              </div>
                            </div>
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="mt-4 p-4 border border-yellow-400 bg-yellow-50 rounded-md">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">
                            {searchTerm ? t('NO_PLAYERS_FOUND') : avaliableData|| t('NO_PLAYERS_AVAILABLE')}
                          </h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            <p>
                              {searchTerm ? t('TRY_DIFFERENTSEARCG') : messageForAdd || t('ADDPLAYERS_TO_ROSTER')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}