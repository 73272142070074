import { Avatar } from "@mui/material";
import React from "react";

export default function ListOL({ items, maxHeight }) {
  return (
    <div className={`overflow-y-auto ${maxHeight}`}>
      <ol className="space-y-4 p-4">
        {items?.map((item, index) => (
          <li key={index} className="flex items-center space-x-4">
            <span className="font-bold font-myteam">{index + 1}</span>
            {item.avatar && (
              <Avatar
                className="w-12 h-12 rounded-full"
                alt="Avatar"
                src={item.avatar}
              />
            )}
            <div className="flex flex-col flex-grow">
              <div className="flex justify-between">
                <p className="text-xl font-myteam font-semibold">{item.primaryText}</p>
                <p className="text-gray-600 font-myteam">{item.secondaryText}</p>
              </div>
              <p className="text-gray-800 font-myteam">{item.thirdText}</p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
