import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { formatDate } from '../utils/Utils';
import MultiColumn from '../components/DataTables/MultiColumn';
import { getRegistrationsDtn, validateRegistrationsClub } from '../service/registrationService';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AuthContext } from '../../../contexts/AuthContext';
import Select from 'react-select';
import { customOption, divisionsData } from '../utils/selectesData';
import Flatpickr from 'react-flatpickr';
import { getAllClubs } from '../service/matcheService';
import { MobileContext } from '../context/isMobileContext';
import { useSnackbar } from 'notistack';
import { Check } from '@mui/icons-material';


export default function DtnPage() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const [initialRecords, setInitialRecords] = useState([]);
    const [search, setSearch] = useState('');
    const navigate = useNavigate()
    const { logout } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar();
    const [originData, setOriginData] = useState([])
    const [dateFilter, setDateFilter] = useState([])
    const [uniqueTeams, setUniqueTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedDivsCategory, setSelectedDivsCategory] = useState(null);
    const isMobile = useContext(MobileContext);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getRegistrationsDtn();
                if (response?.data?.result) {
                    const fetchedData = response.data.result.map(item => ({
                        id: item.id,
                        firstName: item.prenom,
                        lastName: item.nom,
                        email: item.email,
                        birthday: item.date_naissance,
                        picture: item.photoProfil,
                        team: item.equipe.image,
                        phone: item.numTel,
                        equipeId: item.equipeId,
                        categoryDivisions: item.categoryDivisions,
                        status: item.status,
                        action: item,
                        createdAt: item.createdAt,
                        teamName: item.equipe?.slug
                    }));
                    setOriginData(sortBy(fetchedData, 'createdAt').reverse());
                    setInitialRecords(sortBy(fetchedData, 'createdAt').reverse())
                }
            } catch (error) {
                if (error?.response.status == 401) {
                    return logout()
                };
            }
        }
        fetchData();
    }, []);

    const fetchClubs = async () => {
        try {
            const response = await getAllClubs();
            const clubs = response.data;

            const uniqueTeamsMap = new Map();
            clubs.equipe?.forEach(item => {
                if (!uniqueTeamsMap.has(item.name)) {
                    uniqueTeamsMap.set(item.name, { id: item.id.toString(), image: item.image });
                }
            });

            const uniqueTeams = Array.from(uniqueTeamsMap, ([name, { id, image }]) => ({
                value: id,
                label: name,
                logo: image
            }));

            setUniqueTeams(uniqueTeams);
        } catch (error) {
            console.error("Error fetching clubs:", error);
        }
    };
    useEffect(() => {
        fetchClubs()
    }, []);
    useEffect(() => {
        if (selectedTeam || selectedDivsCategory) {
            const newData = originData.filter((item) => {
                const teamMatches = selectedTeam ? item?.equipeId == selectedTeam.value : true;
                const divisionMatches = selectedDivsCategory ? item?.categoryDivisions == selectedDivsCategory.value : true;
                return teamMatches && divisionMatches;
            });
            return setInitialRecords(newData);
        }
        setInitialRecords(prevRecords => {
            const normalizedSearch = search.trim().toLowerCase();
            if (search?.length == 0) {
                return originData
            }
            return prevRecords.filter(item => {
                const normalizedFirstName = item.firstName?.trim().toLowerCase();
                const normalizedLastName = item.lastName?.trim().toLowerCase();
                const normalizedEmail = item.email?.trim().toLowerCase();
                const searchTerms = normalizedSearch?.split(' ');
                return searchTerms?.every(term =>
                    normalizedFirstName?.includes(term) ||
                    normalizedLastName?.includes(term) ||
                    normalizedEmail?.includes(term)
                );
            });
        });
    }, [search, selectedTeam, selectedDivsCategory]);

    const columns = [
        {
            accessor: 'firstName',
            title: t('USER'),
            sortable: false,
            render: ({ firstName, lastName, picture }) => (
                <div className="flex items-center w-max">
                    <img className="w-9 h-9 rounded-full ltr:mr-2 rtl:ml-2 object-cover" src={picture} alt={lastName} />
                    <div>{firstName + ' ' + lastName}</div>
                </div>
            ),
        },
        {
            accessor: 'birthday',
            title: t('BIRTHDAY'),
            sortable: false,
            render: ({ birthday }) => <div>{formatDate(birthday)}</div>,
        },
        { accessor: 'email', title: "Email" },
        {
            accessor: 'team',
            title: t('TEAM'),
            render: ({ team }) => (
                <div className="flex items-center w-max">
                    <img className="w-9 h-9 rounded-full ltr:mr-2 rtl:ml-2 object-cover" src={team} alt={"equipe logo"} />
                </div>
            ),
        },
        {
            accessor: 'status',
            title: t('STATUS'),
            sortable: false,
            render: ({ status }) => (
                status == "en cours" &&
                <span className="badge bg-warning">{status}</span>
            ),
        },
        {
            accessor: 'createdAt',
            title: t('CREATED_AT'),
            sortable: false,
            render: ({ createdAt }) => (
                <div className="flex items-center justify-center text-center w-max">
                    {formatDate(createdAt, 'dateTime')}
                </div>
            ),
        },
        {
            accessor: 'action',
            title: t('ACTION'),
            render: ({ action }) => (
                <div className="flex items-center space-x-3">
                    <button
                        className="group relative p-2 bg-white border border-green-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-green-300 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                        aria-label="Accept"
                        onClick={() => handleAccept(action?.id)}
                    >
                        <Check className="w-5 h-5 text-green-500 group-hover:text-green-600 transition-colors duration-300" />
                        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {t("ACCEPT")}
                        </span>
                    </button>
                    <button
                        className="group relative p-2 bg-white border border-blue-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                        onClick={() => navigate("/dashboard/registration/details", { state: { playerData: action } })}
                        aria-label="View details"
                    >
                        <VisibilityIcon className="w-5 h-5 text-blue-500 group-hover:text-blue-600 transition-colors duration-300" />
                        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {t("VIEW")}
                        </span>
                    </button>
                </div>
            ),
        }
    ];

    const col = ['firstName', 'lastName', 'email', 'birthday', 'teamName', 'status'];
    const header = ['firstName', 'lastName', 'email', 'birthday', 'team', 'status'];

    const handleAccept = async (playerId) => {
        try {
            const validate = await validateRegistrationsClub(playerId);
            if (validate?.status === 200) {
                setOriginData((prevState)=>prevState?.filter(item=>item?.id!=playerId))
                setInitialRecords((prevState)=>prevState?.filter(item=>item?.id!=playerId))
                enqueueSnackbar(t('LICENCE_VALIDATE_SUCCESS'), { variant: 'success' });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const resetFilter = () => {
        setDateFilter(null)
        setSelectedDivsCategory(null)
        setSelectedTeam(null)
    }

    return (
        <div className={`bg-white shadow-md rounded-lg ${isMobile ? "p-1" : "p-6"}`}>
            <div className="flex flex-wrap justify-between items-center">
                <h1 className={`font-bold mb-4 ${isMobile ? " text-center text-xl" : "text-2xl "}`}>{t('LIST_LICENCE_REQUESTS')}</h1>
                <div className="flex flex-wrap ml-auto space-x-4 items-center justify-end">
                    <Select
                        placeholder={t("FILTER_BY_TEAM")}
                        options={uniqueTeams}
                        className="p-2 rounded-md w-64 shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        value={selectedTeam}
                        onChange={(selectedOption) => setSelectedTeam(selectedOption)}
                        components={{ Option: customOption }}
                    />
                    <Select
                        placeholder={t("FILTER_BY_CATRGORY")}
                        options={divisionsData}
                        className="p-2 rounded-md w-64 shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        value={selectedDivsCategory}
                        onChange={(selectedOption) => setSelectedDivsCategory(selectedOption)}
                    />
                    <Flatpickr
                        value={dateFilter}
                        placeholder={t('FILTER_BY_DATE')}
                        options={{ dateFormat: 'Y-m-d' }}
                        className="form-input p-2 w-64 h-10 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        onChange={(dates) => setDateFilter(dates)}
                    />
                    <button type="button" className={`btn btn-primary ${isMobile ? "mt-2" : ""}`} onClick={resetFilter}>
                        {t("RESET_FILTER")}
                    </button>
                </div>
            </div>

            <MultiColumn
                PDF_TITLE={t("LIST_LICENCE_REQUESTS")}
                USER_NAME={`${user?.nom} ${user?.prenom}`}
                search={search} setSearch={setSearch}
                setInitialRecords={setInitialRecords}
                initialRecords={initialRecords}
                rowData={initialRecords}
                columns={columns} enableExport={true}
                col={col} header={header} />
        </div>
    )
}
