import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addEventToState } from "../../../redux/actions/matchEventsActions";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { getReservesTeamplate } from "../../../redux/middleware/matchReservesThunks";
import ModalSelectPlayerMobile from "../../../backoffice/src/components/Modal/ModalSelectPlayerMobile";
import { MobileContext } from "../../../backoffice/src/context/isMobileContext";

const DisciplineEvent = ({
  t,
  playerSelected,
  setPlayerSelected,
  currentTeam,
  teamRecevant,
  teamVisiteuse,
  players,
}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedMotif, setSelectedMotif] = useState("");
  const [RegularTime, setRegularTime] = useState(0);
  const [extraTime, setExtraTime] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [template, setTemplate] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const events = useSelector((state) => state.matchEventsReducer.events);
  const [selectedPlayerMobile, setselectedPlayerMobile] = useState(null);
  const [openModalSelectPlayer, setOpenModalSelectPlayer] = useState(false);
  const isMobile = useContext(MobileContext);

  const fetchTeamplate = async () => {
    const data = await getReservesTeamplate("cards", "");
    setTemplate(data);
  };
  useEffect(() => {
    fetchTeamplate();
  }, [selectedCard]);
  const handleCardSelect = (card) => {
    setSelectedCard(card);
  };

  const handleMotifSelect = (event) => {
    setSelectedMotif(event?.target?.value);
  };

  const handleVider = () => {
    setSelectedCard(null);
    setSelectedMotif("");
    setRegularTime(0);
    setExtraTime(0);
    setAdditionalInfo("");
    setPlayerSelected([]);
    setselectedPlayerMobile(null);
    setTemplate([]);
  };

  const handleValider = () => {
    if (playerSelected.length === 0 && selectedPlayerMobile == null) {
      const variant = "error";
      enqueueSnackbar(t("SELECT_USER"), { variant });
      return;
    }
    if (!selectedCard) {
      const variant = "error";
      enqueueSnackbar(t("SELECT_CARD"), { variant });
      return;
    }
    if (!selectedMotif) {
      const variant = "error";
      enqueueSnackbar(t("SELECT_MOTIF"), { variant });
      return;
    }
    if (!RegularTime) {
      const variant = "error";
      enqueueSnackbar(t("SELECT_TIME"), { variant });
      return;
    }

    const existingEvents = events?.filter((event) => {
      if (event.eventName === "discipline") {
        const playerSelectByTable = playerSelected?.some(
          (player) => event?.pers[0]?.id === player?.id
        );
        const playerSelectByMobileModal =
          selectedPlayerMobile?.id == event?.pers[0]?.id;
        return playerSelectByTable || playerSelectByMobileModal;
      }
    });

    var hasRedCard = false;
    var yellowCardCount = 0;

    existingEvents.forEach((event) => {
      if (event.eventName === "discipline") {
        if (event.cardDiscipline === "red") {
          hasRedCard = true;
        } else if (event.cardDiscipline === "yellow") {
          yellowCardCount++;
        }
      }
    });

    if (hasRedCard) {
      enqueueSnackbar(t("PLAYER_ALREADY_HAS_RED_CARD"), { variant: "error" });
      return;
    }

    if (yellowCardCount >= 2) {
      enqueueSnackbar(t("PLAYER_ALREADY_HAS_TWO_YELLOW_CARDS"), {
        variant: "error",
      });
      return;
    }

    if (selectedCard === "yellow" && yellowCardCount === 1) {
      //selectedCard = "red";
      setSelectedCard("red");
    }

    const isValidRegularTime =
      RegularTime === 45 ||
      RegularTime === 90 ||
      RegularTime === 105 ||
      RegularTime === 120;

    const disciplineEvent = {
      team: {
        id:
          currentTeam === "teamRecevant" ? teamRecevant?.id : teamVisiteuse?.id,
        name:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        logo:
          currentTeam === "teamRecevant"
            ? teamRecevant?.logo
            : teamVisiteuse?.logo,
      },
      pers:
        playerSelected && playerSelected?.length != 0
          ? playerSelected
          : [selectedPlayerMobile],
      cardDiscipline: selectedCard,
      eventName: "discipline",
      Motif: selectedMotif,
      RegularTime,
      extraTime: isValidRegularTime ? extraTime : 0,
      additionalInfo,
    };

    dispatch(addEventToState(disciplineEvent));
    handleVider();
  };

  return (
    <div className="border border-gray-300 rounded-lg">
      {isMobile ? (
        <div>
          <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
            <h1 className="text-lg font-myteam font-bold mb-4">
              {t("SELECT_PLAYER")}
            </h1>
            <div className="border flex w-full flex-wrap items-center border-gray-300 p-4 rounded-lg bg-white shadow">
              {selectedPlayerMobile ? (
                <div className="w-full flex items-center space-x-4">
                  <img
                    src={selectedPlayerMobile.image}
                    alt={selectedPlayerMobile.fullName}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <div className="font-myteam">
                    <p className="font-bold text-gray-800">
                      {selectedPlayerMobile.fullName}
                    </p>
                    <p className="text-sm text-gray-600">
                      {t("SHIRT_NUMBER")}: {selectedPlayerMobile.shirtNmbr}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="font-myteam w-full">
                  <button
                    onClick={() => setOpenModalSelectPlayer(true)}
                    className="bg-blue-500 text-white rounded-md px-4 py-2 w-full"
                  >
                    {t("SELECT_PLAYER")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
        <h1 className="text-lg font-myteam font-bold mb-4">{t("CARTON")}</h1>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
          <div
            className={`border flex flex-wrap items-center justify-around border-gray-300 p-4 rounded-lg bg-white shadow ${
              selectedCard === "red" ? "bg-gray-200" : ""
            }`}
            onClick={() => handleCardSelect("red")}
          >
            <div className="font-myteam font-semibold mb-2 relative">
              <img
                src="../../assets/images/footCards/red.png"
                className="w-8 h-12"
              />
              {selectedCard === "red" && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0a10 10 0 100 20 10 10 0 000-20zM7 14.585l-3.293-3.293a1 1 0 011.414-1.414L7 11.758l6.293-6.293a1 1 0 111.414 1.414L7.707 14.586a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>

          <div
            className={`border flex flex-wrap items-center justify-around border-gray-300 p-4 rounded-lg bg-white shadow ${
              selectedCard === "yellow" ? "bg-gray-200" : ""
            }`}
            onClick={() => handleCardSelect("yellow")}
          >
            <div className="font-myteam font-semibold mb-2 relative">
              <img
                src="../../assets/images/footCards/yellow.png"
                className="w-8 h-12"
              />
              {selectedCard === "yellow" && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0a10 10 0 100 20 10 10 0 000-20zM7 14.585l-3.293-3.293a1 1 0 011.414-1.414L7 11.758l6.293-6.293a1 1 0 111.414 1.414L7.707 14.586a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>

          <div
            className={`border flex flex-wrap items-center justify-around border-gray-300 p-4 rounded-lg bg-white shadow ${
              selectedCard === "Double_yellow_card" ? "bg-gray-200" : ""
            }`}
            onClick={() => handleCardSelect("Double_yellow_card")}
          >
            <div className="font-myteam font-semibold mb-2 relative">
              <img
                src="../../assets/images/footCards/Double_yellow_card.png"
                className="w-14 h-12"
              />
              {selectedCard === "Double_yellow_card" && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0a10 10 0 100 20 10 10 0 000-20zM7 14.585l-3.293-3.293a1 1 0 011.414-1.414L7 11.758l6.293-6.293a1 1 0 111.414 1.414L7.707 14.586a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div
            className={`border flex flex-wrap items-center justify-around border-gray-300 p-4 rounded-lg bg-white shadow ${
              selectedCard === "yellowRed" ? "bg-gray-200" : ""
            }`}
            onClick={() => handleCardSelect("yellowRed")}
          >
            <div className="font-myteam font-semibold mb-2 relative">
              <img
                src="../../assets/images/footCards/yellowRed.png"
                className="w-10 h-12"
              />
              {selectedCard === "yellowRed" && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0a10 10 0 100 20 10 10 0 000-20zM7 14.585l-3.293-3.293a1 1 0 011.414-1.414L7 11.758l6.293-6.293a1 1 0 111.414 1.414L7.707 14.586a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center bg-gray-100 rounded-lg shadow-lg">
        <div className="p-6">
          <h1 className="text-lg font-myteam font-bold mb-4">{t("MINUTE")}</h1>
          <div className="border flex w-max flex-wrap items-center border-gray-300 p-4 rounded-lg bg-white shadow">
            <div className="p-2">
              <input
                type="number"
                value={RegularTime}
                style={{
                  backgroundColor: "#fafafa",
                }}
                min={0}
                max={50}
                onChange={(e) => setRegularTime(parseInt(e.target.value))}
                className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <label>+</label>
            <div className="p-2">
              <input
                type="number"
                style={{
                  backgroundColor: "#fafafa",
                }}
                disabled={
                  !(
                    RegularTime === 45 ||
                    RegularTime === 90 ||
                    RegularTime === 105 ||
                    RegularTime === 120
                  )
                }
                value={extraTime}
                min={0}
                max={50}
                onChange={(e) => setExtraTime(parseInt(e.target.value))}
                className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>
        <div className="p-6">
          <h1 className="text-lg font-myteam font-bold mb-4">{t("MOTIF")}</h1>
          <div className="border flex w-max flex-wrap items-center border-gray-300 p-4 rounded-lg bg-white shadow">
            <div className={`font-myteam`}>
              <select
                style={{
                  backgroundColor: "#fafafa",
                }}
                onChange={handleMotifSelect}
              >
                {template?.length == 0 ? (
                  <option value="">{t("NO_TEAMPLATE_AVAILABLE")}</option>
                ) : (
                  <option value="">{t("RESION_CARD")}</option>
                )}

                {template?.map((tmp) => {
                  return (
                    <option key={tmp.id} value={tmp.id}>
                      {t(tmp.title)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      {openModalSelectPlayer ? (
        <ModalSelectPlayerMobile
          players={players}
          isOpen={openModalSelectPlayer}
          callback={(ply) => setselectedPlayerMobile(ply)}
          onClose={() => setOpenModalSelectPlayer(false)}
        />
      ) : null}
      <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
        <div className="flex justify-between">
          <button
            className="px-4 mx-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
            onClick={handleVider}
          >
            {t("EMPTY")}
          </button>
          <button
            className="px-4 mx-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
            onClick={handleValider}
          >
            {t("VALIDER")}
          </button>
        </div>
      </div>
      {/* <div className="bg-gray-100 rounded-lg shadow-lg">
        <div className="p-6">
          <h1 className="text-2xl font-myteam font-bold mb-4 text-left">
            {t("ADDITIONAL_INFORMATION")}
          </h1>
          <div className="border flex w-max flex-wrap items-center border-gray-300 p-4 rounded-lg bg-white shadow">
            <div className="p-2">
              <input
                type="text"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DisciplineEvent;
