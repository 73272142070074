import { Avatar } from "@mui/material";
import React from "react";
import Signature from "../../Forms/signature/Signature";

export default function Signatures({
  setOpen,
  t,
  teamRecevant,
  teamVisiteuse,
  signatures,
  handleSignatureUpdate,
  matchId,
  isArbiture,
  type
}) {
  return (
    <div className="p-5 bg-gray-100">
      <div className="font-myteam font-bold text-center m-5">
        <h1 className="p-4 font-bold text-2xl md:text-4xl">
          {t("SIGNATEUR_AFTER_MATCH")}
        </h1>
      </div>
      <div className="flex items-center justify-around">
        <div className="text-center m-5">
          <div className="flex justify-center items-center mb-2">
            <Avatar
              src={teamRecevant?.logo}
              className="w-10 h-10 rounded-full mr-2"
            />
            <h1 className="font-myteam text-xl">{teamRecevant?.name}</h1>
          </div>
          <Signature
            t={t}
            role="teamRecevant"
            isSigned={signatures.teamRecevant}
            handleSignatureUpdate={handleSignatureUpdate}
            teamId={teamRecevant?.id}
            matchId={matchId}
            type={type}
          />
        </div>

        <div className="text-center m-5">
          <div className="flex justify-center items-center mb-2">
            <Avatar
              src={"../../assets/images/referee.png"}
              className="w-10 h-10 rounded-full mr-2"
            />
            <h1 className="font-myteam text-xl">{t("ARBITRE")}</h1>
          </div>
          <Signature
            t={t}
            role="arbiture"
            isSigned={signatures.arbiture}
            handleSignatureUpdate={handleSignatureUpdate}
            isArbiture={isArbiture}
            matchId={matchId}
            type={type}
          />
        </div>

        <div className="text-center m-5">
          <div className="flex justify-center items-center mb-2">
            <h1 className="font-myteam text-xl mr-2">{teamVisiteuse?.name}</h1>
            <Avatar
              src={teamVisiteuse?.logo}
              className="w-10 h-10 rounded-full"
            />
          </div>
          <Signature
            t={t}
            role="teamVisiteuse"
            isSigned={signatures.teamVisiteuse}
            handleSignatureUpdate={handleSignatureUpdate}
            teamId={teamVisiteuse?.id}
            matchId={matchId}
            type={type}
          />
        </div>
      </div>
    </div>
  );
}
