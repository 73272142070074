import React from 'react'
import Formation from '../../../../components/LineUp/Formation'
import { useTranslation } from 'react-i18next'

export default function LineUps(props) {
    const { t } = useTranslation()

    const team1 = props.match?.equipeHome?.id == props?.events[0].equipe_id ? props.match.equipeHome : props.match.equipeAway
    const team2 = props.match?.equipeHome?.id == props?.events[1].equipe_id ? props.match.equipeHome : props.match.equipeAway

    return (
        <div className='my-5'>
            <div className="flex justify-center">
                <div className="col-6 mx-1">
                    <div className="widget card widget--sidebar widget-lineup">
                        <div className="widget__title card__header">
                            <div className='flex items-center'>
                                <img src={team1?.image} alt={`${team1?.name} `} className="w-8 h-8 mx-2 rounded-full" />
                                <h4 className='font-bold'> {team1?.name}</h4>
                            </div>
                            <h4>{props?.events[0].formation}</h4>
                        </div>
                        <div className="widget__content card__content flex items-start">
                            <div className='w-1/2'>
                                <h2 className='font-bold m-2'>
                                    {t('SUBSTITUTE_PLAYERS')}
                                </h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="lineup__num">{t("NBR")}</th>
                                            <th className="lineup__pos">{t("POS")}</th>
                                            <th className="lineup__name">{t("FULL_NAME")}</th>
                                            <th className="lineup__info" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props?.events[0].ListReplacment?.map(player => (
                                                <tr key={player.id}>
                                                    <td className="lineup__num">{player?.shirtNmbr || "-"}</td>
                                                    <td className="lineup__pos">{player?.post || "-"}</td>
                                                    <td className="lineup__name flex items-center">
                                                        <img src={player.image} alt={`${player.nom} ${player.prenom}`} className="w-8 h-8 mx-2 rounded-full" />
                                                        {player.nom + " " + player.prenom}
                                                    </td>
                                                    <td className="lineup__info">
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-1/2 h-[600px]">
                                <div className='h-[350px]'>
                                    <Formation
                                        players={props?.events[0].ListPlayer}
                                        showFilterOptions={false}
                                        defaultFormation={
                                            props?.events[0].formation
                                        }
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-6 mx-1">
                    <div className="widget card widget--sidebar widget-lineup">
                        <div className="widget__title card__header">
                            <div className='flex items-center'>
                                <img src={team2?.image} alt={`${team2?.name} `} className="w-8 h-8 mx-2 rounded-full" />
                                <h4 className='font-bold'> {team2?.name}</h4>
                            </div>
                            <h4>{props?.events[1].formation}</h4>
                        </div>
                        <div className="widget__content card__content flex items-start">
                            <div className="w-1/2 h-[600px]">
                                <div className='h-[350px]'>
                                    <Formation
                                        players={props?.events[1].ListPlayer}
                                        showFilterOptions={false}
                                        defaultFormation={
                                            props?.events[1].formation
                                        }
                                    />
                                </div>
                            </div>
                            <div className='w-1/2'>
                                <h2 className='font-bold m-2'>
                                    {t('SUBSTITUTE_PLAYERS')}
                                </h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="lineup__num">{t("NBR")}</th>
                                            <th className="lineup__pos">{t("POS")}</th>
                                            <th className="lineup__name">{t("FULL_NAME")}</th>
                                            <th className="lineup__info" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props?.events[1].ListReplacment?.map(player => (
                                                <tr key={player.id}>
                                                    <td className="lineup__num">{player?.shirtNmbr || "-"}</td>
                                                    <td className="lineup__pos">{player?.post || "-"}</td>
                                                    <td className="lineup__name flex items-center">
                                                        <img src={player.image} alt={`${player.nom} ${player.prenom}`} className="w-8 h-8 mx-2 rounded-full" />
                                                        {player.nom + " " + player.prenom}
                                                    </td>
                                                    <td className="lineup__info">
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
