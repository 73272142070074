import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//import Header from "../components/Headers/Header";
import Footer from "../components/Footers/Footer";
import Header from "../backoffice/src/components/Layouts/Header";
//import DefaultLayout from "../backoffice/src/components/Layouts/DefaultLayout";

const Layout = ({ children }) => {
  const location = useLocation();
  const showHeader = [
    "/matches",
    "/match/prepare",
    "/match/events",
    "/match/fuille",
    "/match/reserve",
  ].includes(location.pathname);
  const showFooter = [
   // "/login",
    "/matches",
    "/match/prepare",
    "/match/events",
    "/match/fuille",
    "/match/reserve",
  ].includes(location.pathname);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("myteamConnectToken");
    if (!token) {
      navigate("/login");
    }
    if (["/login"]?.includes(location.pathname) && token) {
      navigate("/matches");
    }
  }, []);

  return (
    <div className=" bg-gray-200">
      {/* <DefaultLayout>{children}</DefaultLayout> */}
      {showHeader && <Header />}
      {children}
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
