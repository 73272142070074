import axiosInstance from "../axiosConfig";

export const getMatchesByUserId = async () => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_MATCH}listMatches`
    );
    const data = { matches: response.data };
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw new Error("Failed to fetch matches");
  }
};

export const getTeam = async (matchId) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_ENDPOINT_TEAM}myteam/${matchId}`
    );

    const myTeamData = {
      myTeam: response.data,
    };
    return myTeamData;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw new Error("Failed to fetch players");
  }
};
export const getTeamByCategory = async (category) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_ENDPOINT_TEAM}myteamByCategory/${category}`
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (error.response.status === 404) {
      throw new Error("You don't have a team in this category.");
    }
    throw new Error("Failed to fetch players");
  }
};
export const fetchTeamComposition = async (matchId, teamId) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_MATCH}load-team`,
      {
        match: matchId,
        equipe: teamId,
      }
    );
    return response?.data;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw new Error("Failed to fetch composition");
  }
};

export const updateTeamComposition = async (composition) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_MATCH}add-team`,
      composition
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw new Error("Failed to update team composition");
  }
};

export const validateTeamComposition = async (matchId, equipeId, status) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_MATCH}prepare-match`,
      {
        match: matchId,
        equipe: equipeId,
        status: status,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw new Error("Failed to update team composition");
  }
};
export const matchSignateur = async (signateurData) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_MATCH}sign-match`,
      signateurData
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Unauthorized");
    }
    throw new Error("Failed to signed team");
  }
};
