import React, { useEffect, useState } from 'react';
import ImageUpload from '../UploadFiles/ImageUpload';
import { useTranslation } from 'react-i18next';
import { createRegistrationClub } from '../../../service/registrationService';
import UploadFile from '../UploadFiles/UploadFile';
import FileUpload from '../UploadFiles/FileUpload';
import { formatDate } from '../../../utils/Utils';
import SmallLoading from '../../../../../components/Loadings/SmallLoading';

const Documents = ({ setStep, step, registrationId, defaultValues }) => {
  const { t } = useTranslation();

  const savedState = JSON.parse(localStorage.getItem('detailsState')) || {};
  const [firstName, setFirstName] = useState(savedState.firstName || defaultValues?.prenom || '');
  const [lastName, setLastName] = useState(savedState.lastName || defaultValues?.nom || '');
  const [dateOfBirth, setDateOfBirth] = useState(savedState.dateOfBirth || defaultValues?.date_naissance || null);

  const [photoProfile, setPhotoProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [certificationMedical, setCertificationMedical] = useState(null);

  const [photoProfileError, setPhotoProfileError] = useState('');
  const [photoIdError, setPhotoIdError] = useState('');
  const [certificationMedicalError, setCertificationMedicalError] = useState('');


  useEffect(() => {
    if (!registrationId && !defaultValues?.id) {
      setStep(step - 1)
    }
  }, [registrationId, setStep, step, defaultValues]);


  const handleSubmit = async () => {
    setPhotoProfileError('');
    setPhotoIdError('');
    setCertificationMedicalError('');
    let formIsValid = true;

    if (!photoProfile && !defaultValues?.photoProfil) {
      setPhotoProfileError(t('MESSAGES_ERROR.PROFILE_PICTURE_REQUIRED'));
      formIsValid = false;
    }

    if (!photoId && !defaultValues?.photoIdentite) {
      setPhotoIdError(t('MESSAGES_ERROR.ID_PICTURE_REQUIRED'));
      formIsValid = false;
    }

    if (!certificationMedical && !defaultValues?.certificationMedical) {
      setCertificationMedicalError(t('MESSAGES_ERROR.MEDICAL_CERTIFICATION_REQUIRED'));
      formIsValid = false;
    }

    if (!formIsValid) {
      return;
    }
    setLoading(true);
    try {
      await createRegistrationClub({
        fullName: defaultValues ?
          defaultValues?.nom + '_' + defaultValues?.prenom + "-" + formatDate(defaultValues?.date_naissance, "full") :
          firstName + '_' + lastName + "-" + formatDate(dateOfBirth, 'full'),
        FilePhotoProfile: photoProfile,
        FilePhotoIdentite: photoId,
        FileCertificationMedical: certificationMedical,
      }, step, registrationId || defaultValues?.id);

      setStep(step + 1);
    } catch (error) {
      console.error('Error creating registration:', error);
    } finally {
      setLoading(false)
    }
  };


  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-end">
        <div className="p-2">
          <FileUpload
            title={`${t("PICTURE_PROFILE")} (${t("PICTURE")}) *`}
            onFileSelect={(file) => setPhotoProfile(file)}
            defaultImage={defaultValues?.photoProfil}
            uploadType="image"
          />
          {photoProfileError && <p className="text-red-500">{photoProfileError}</p>}
        </div>
        <div className="p-2">
          <FileUpload
            title={`${t("PICTURE_ID")} (${t("PICTURE")}/${t("PDF")}) *`}
            onFileSelect={(file) => setPhotoId(file)}
            defaultImage={defaultValues?.photoIdentite}
            uploadType="image/pdf"
          />
          {photoIdError && <p className="text-red-500">{photoIdError}</p>}
        </div>
        <div className="p-2">
          <FileUpload
            title={`${t("CERTIFICATION_MEDICAL")} (${t("PICTURE")}/${t("PDF")}) *`}
            onFileSelect={(file) => setCertificationMedical(file)}
            defaultImage={defaultValues?.certificationMedical}
            uploadType="image/pdf"
          />
          {certificationMedicalError && <p className="text-red-500">{certificationMedicalError}</p>}
        </div>
      </div>

      <div className="flex justify-between m-5">
        <button
          type="button"
          className={`btn btn-primary ${step === 1 ? 'hidden' : ''}`}
          onClick={() => setStep(step === 1 ? 1 : step - 1)}
        >
          {t("BACK")}
        </button>
        {
          loading ? <SmallLoading /> : <button
            type="button"
            className="btn btn-primary ltr:ml-auto rtl:mr-auto"
            onClick={handleSubmit}
          >
            {step === 5 ? t("FINISH") : t("NEXT")}
          </button>
        }

      </div>
    </>
  );
};

export default Documents;
