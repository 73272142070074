export const getReserveMotifText = (
  value,
  user,
  match,
  currentTeam,
  teamRecevant,
  teamVisiteuse,
  userSelected,
  t
) => {
  switch (value) {
    case "MOTIF_SUSPENDED_PLAYER":
      return t("MOTIF_SUSPENDED_PLAYER", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_slug: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_MATCH_INCIDENT_REQUALIFICATION":
      return t("MOTIF_MATCH_INCIDENT_REQUALIFICATION", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_LICENSE_ISSUE":
      return t("MOTIF_LICENSE_ISSUE", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_DOUBLE_MATCH_WITHIN_48H":
      return t("MOTIF_DOUBLE_MATCH_WITHIN_48H", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_QUALIFICATION_DELAY":
      return t("MOTIF_QUALIFICATION_DELAY", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_MEDICAL_AUTHORIZATIONS":
      return t("MOTIF_MEDICAL_AUTHORIZATIONS", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_OFFICIAL_FUNCTION_DESPITE_SUSPENSION":
      return t("MOTIF_OFFICIAL_FUNCTION_DESPITE_SUSPENSION", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_ARBITRATION_RULES_INFRACTION":
      return t("MOTIF_ARBITRATION_RULES_INFRACTION", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    case "MOTIF_OPPONENT_NO_OFFICIAL_LICENSE":
      return t("MOTIF_OPPONENT_NO_OFFICIAL_LICENSE", {
        nom: user?.nom,
        prenom: user?.prenom,
        equipe_name: user?.equipe_slug,
        match_id: match?.id,
        match_date: match?.date,
        category_divisions: match?.match?.category_divisions,
        teamName:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        fullName: userSelected?.fullName,
        licenceNumber: userSelected?.licenceNumber,
      });
    default:
      return "";
  }
};
