import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { classificationData, divisionsData, niveauData, rolesData, sportsData } from '../../../utils/selectesData';
import { createRegistrationClub } from '../../../service/registrationService';

export default function Enregistrements({ t, setStep, step, defaultValues, registrationId }) {
  const savedState = JSON.parse(localStorage.getItem('formStateEnregistrement')) || {};
  const [role, setRole] = useState(savedState.role?.value || defaultValues?.role || "");
  const [sport, setSport] = useState(savedState.sport?.value || defaultValues?.sport || t("FOOTBALL"));
  const [level, setLevel] = useState(savedState.level?.value || defaultValues?.niveau || "");
  const [ageClassification, setAgeClassification] = useState(savedState.ageClassification?.value || defaultValues?.cateAge || "");
  const [competitiveDivisions, setCompetitiveDivisions] = useState(savedState.competitiveDivisions?.value || defaultValues?.categoryDivisions || "");

  const [roleError, setRoleError] = useState('');
  const [sportError, setSportError] = useState('');
  const [levelError, setLevelError] = useState('');
  const [ageClassificationError, setAgeClassificationError] = useState('');
  const [competitiveDivisionsError, setCompetitiveDivisionsError] = useState('');

  useEffect(() => {
    const formState = {
      role,
      sport,
      level,
      ageClassification,
      competitiveDivisions,
      step,
    };
    localStorage.setItem('formStateEnregistrement', JSON.stringify(formState));
  }, [role, sport, level, ageClassification, competitiveDivisions, step]);


  useEffect(() => {
    if (!registrationId && !defaultValues?.id) {
      setStep(step - 1)
    }
  }, [registrationId, setStep, step, defaultValues]);

  const handleClearError = () => {
    setRoleError('');
    setSportError('');
    setLevelError('');
    setAgeClassificationError('');
    setCompetitiveDivisionsError('');
  }

  const handleSubmit = async () => {
    handleClearError();

    let formIsValid = true;

    if (!role) {
      setRoleError(t('MESSAGES_ERROR.ROLE_REQUIRED'));
      formIsValid = false;
    }

    if (!sport) {
      setSportError(t('MESSAGES_ERROR.SPORT_REQUIRED'));
      formIsValid = false;
    }

    if (!level) {
      setLevelError(t('MESSAGES_ERROR.LEVEL_REQUIRED'));
      formIsValid = false;
    }

    if (!ageClassification) {
      setAgeClassificationError(t('MESSAGES_ERROR.AGE_CLASSIFICATION_REQUIRED'));
      formIsValid = false;
    }

    if (!competitiveDivisions) {
      setCompetitiveDivisionsError(t('MESSAGES_ERROR.COMPETITIVE_DIVISIONS_REQUIRED'));
      formIsValid = false;
    }

    if (!formIsValid) {
      return;
    }

    try {
      await createRegistrationClub({
          role: t(role?.value,{lng:"fr"}) || role,
          sport: t(sport?.value,{lng:"fr"}) || sport,
          level: t(level?.value,{lng:"fr"}) || level,
          cateAge: t(ageClassification?.value,{lng:"fr"}) || ageClassification,
          category_age: t(ageClassification?.value,{lng:"fr"}) || ageClassification,
          category_divisions: competitiveDivisions?.value || competitiveDivisions,
        }, step, registrationId || defaultValues?.id);

      setStep(step + 1);
    } catch (error) {
      console.error('Error creating registration:', error);
    }
  };

  return (
    <form noValidate className="space-y-5 mx-4 my-4">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="role">{t('ROLE')} <span className='text-red-500'>*</span></label>
          <Select
            options={rolesData}
            defaultInputValue={role}
            getOptionLabel={(option) => t(option.label)}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            onChange={(selectedOption) => { setRole(selectedOption); handleClearError() }}
            className={`custom-multiselect ${roleError ? 'border-red-500' : ''}`}
          />
          {roleError && <p className="text-red-500">{roleError}</p>}
        </div>
        <div>
          <label htmlFor="sport">{t('SPORT')} <span className='text-red-500'>*</span></label>
          <Select
            options={sportsData}
            getOptionLabel={(option) => t(option.label)}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            defaultInputValue={sport}
            onChange={(selectedOption) => { setSport(selectedOption); handleClearError() }}
            className={`custom-multiselect ${sportError ? 'border-red-500' : ''}`}
          />
          {sportError && <p className="text-red-500">{sportError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="level">{t('LEVEL')} <span className='text-red-500'>*</span></label>
          <Select
            options={niveauData}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            defaultInputValue={level}
            getOptionLabel={(option) => t(option.label)}
            onChange={(selectedOption) => { setLevel(selectedOption); handleClearError() }}
            className={`custom-multiselect ${levelError ? 'border-red-500' : ''}`}
          />
          {levelError && <p className="text-red-500">{levelError}</p>}
        </div>
        <div>
          <label htmlFor="ageClassification">{t("AGE_CLASSIFICATION")} <span className='text-red-500'>*</span></label>
          <Select
            options={classificationData}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            getOptionLabel={(option) => t(option.label)}
            defaultInputValue={ageClassification}
            onChange={(selectedOption) => { setAgeClassification(selectedOption); handleClearError() }}
            className={`custom-multiselect ${ageClassificationError ? 'border-red-500' : ''}`}
          />
          {ageClassificationError && <p className="text-red-500">{ageClassificationError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="competitiveDivisions">{t("COMPETITIVE_DIVISIONS")} <span className='text-red-500'>*</span></label>
          <Select
            options={divisionsData}
            defaultInputValue={competitiveDivisions}
            onChange={(selectedOption) => { setCompetitiveDivisions(selectedOption); handleClearError() }}
            className={`custom-multiselect ${competitiveDivisionsError ? 'border-red-500' : ''}`}
          />
          {competitiveDivisionsError && <p className="text-red-500">{competitiveDivisionsError}</p>}
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className={`btn btn-primary ${step === 1 ? 'hidden' : ''}`}
          onClick={() =>
            setStep(step === 1 ? 1 : step - 1)
          }
        >
          {t("BACK")}
        </button>
        <button
          type="button"
          className="btn btn-primary ltr:ml-auto rtl:mr-auto"
          onClick={handleSubmit}
        >
          {step === 5 ? t("FINISH") : t("NEXT")}
        </button>
      </div>
    </form>
  );
}
