import { DataTable } from 'mantine-datatable';
import { useContext, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { downloadExcel } from 'react-export-table-to-excel';
import IconFile from '../Icon/IconFile';
import IconPrinter from '../Icon/IconPrinter';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '../../context/isMobileContext';


const MultiColumn = ({ loading, rowData, columns, col, header, enableExport, title, initialRecords, search, setSearch, setInitialRecords, PDF_TITLE, USER_NAME }) => {
    const { t } = useTranslation()
    const [page, setPage] = useState(1);
    const PAGE_SIZES = [10, 20, 30, 50, 100];
    const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);
    const [recordsData, setRecordsData] = useState(initialRecords);
    const [sortStatus, setSortStatus] = useState({
        columnAccessor: 'id',
        direction: 'asc',
    });
    const isMobile=useContext(MobileContext)
    useEffect(() => {
        setPage(1);
    }, [pageSize]);
    useEffect(() => {
        const from = (page - 1) * pageSize;
        const to = from + pageSize;
        setRecordsData([...initialRecords.slice(from, to)]);
    }, [page, pageSize, initialRecords]);


    useEffect(() => {
        const data = sortBy(initialRecords, sortStatus.columnAccessor);
        setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
        setPage(1);
    }, [sortStatus]);

    function handleDownloadExcel() {
        downloadExcel({
            fileName: 'table',
            sheet: 'react-export-table-to-excel',
            tablePayload: {
                header,
                body: rowData,
            },
        });
    }

    const exportTable = (type) => {
        let columns = col;
        let records = rowData;
        let filename = 'table';
        let newVariable = window.navigator;

        // Add your dynamic title, user info, and date here
        const dynamicTitle = PDF_TITLE; // Change as needed
        const userName = USER_NAME;
        const date = new Date().toLocaleDateString(); // Format the date as needed

        if (type === 'csv') {
            let coldelimiter = ';';
            let linedelimiter = '\n';
            let result = columns
                .map((d) => capitalize(d))
                .join(coldelimiter);
            result += linedelimiter;
            records.map((item) => {
                columns.map((d, index) => {
                    if (index > 0) result += coldelimiter;
                    let val = item[d] ? item[d] : '';
                    result += val;
                });
                result += linedelimiter;
            });
            if (!result.match(/^data:text\/csv/i) && !newVariable.msSaveOrOpenBlob) {
                var data = 'data:application/csv;charset=utf-8,' + encodeURIComponent(result);
                var link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', filename + '.csv');
                link.click();
            } else {
                var blob = new Blob([result]);
                if (newVariable.msSaveOrOpenBlob) {
                    newVariable.msSaveBlob(blob, filename + '.csv');
                }
            }
        } else if (type === 'print') {
            var rowhtml = '<p style="text-align:center;">' + dynamicTitle + '</p>';
            rowhtml += '<p style="text-align:center;">Date: ' + date + ' | User: ' + userName + '</p>';
            rowhtml += '<img src="../assets/images/logos/myteamConnect.png" alt="App Logo" style="position:absolute;top:10;left:10;width:10%">';
            rowhtml += '<table style="width: 100%; " cellpadding="0" cellcpacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> ';
            header?.map((d) => {
                rowhtml += '<th>' + capitalize(d) + '</th>';
            });
            rowhtml += '</tr></thead>';
            rowhtml += '<tbody>';
            records.map((item) => {
                rowhtml += '<tr>';
                columns?.map((d) => {
                    let val = item[d] ? item[d] : '';
                    rowhtml += '<td>' + val + '</td>';
                });
                rowhtml += '</tr>';
            });
            rowhtml += '<style>body {font-family:Arial; color:#495057;} p {text-align:center; font-size:18px; font-weight:bold; margin:15px;} table {border-collapse: collapse; border-spacing: 0;} th, td {font-size:12px; text-align:left; padding: 4px;} th {padding:8px 4px;} tr:nth-child(2n-1) {background:#f7f7f7; } img {display: block; margin: auto;}</style>';
            rowhtml += '</tbody></table>';
            var winPrint = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
            winPrint.document.write('<title>Print</title>' + rowhtml);
            winPrint.document.close();
            winPrint.focus();
            winPrint.print();
        } else if (type === 'txt') {
            let coldelimiter = ',';
            let linedelimiter = '\n';
            let result = columns
                .map((d) => capitalize(d))
                .join(coldelimiter);
            result += linedelimiter;
            records.map((item) => {
                columns.map((d, index) => {
                    if (index > 0) result += coldelimiter;
                    let val = item[d] ? item[d] : '';
                    result += val;
                });
                result += linedelimiter;
            });
            if (!result.match(/^data:text\/txt/i) && !newVariable.msSaveOrOpenBlob) {
                var data1 = 'data:application/txt;charset=utf-8,' + encodeURIComponent(result);
                var link1 = document.createElement('a');
                link1.setAttribute('href', data1);
                link1.setAttribute('download', filename + '.txt');
                link1.click();
            } else {
                var blob1 = new Blob([result]);
                if (newVariable.msSaveOrOpenBlob) {
                    newVariable.msSaveBlob(blob1, filename + '.txt');
                }
            }
        }
    };


    const capitalize = (text) => {
        return text
            .replace('_', ' ')
            .replace('-', ' ')
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    return (
        <div className={`mt-6 ${isMobile&&"p-1"}`}>
            {title && <h3 className="text-xl font-bold mb-4">{title}</h3>}
            <div className="flex flex-wrap md:items-center justify-between md:flex-row mb-4.5 gap-5">
                {
                    enableExport && <div className="flex items-center">
                        <button type="button" className="btn btn-primary btn-sm m-1" onClick={handleDownloadExcel}>
                            <IconFile className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                            {t("EXCEL")}
                        </button>
                        <button type="button" onClick={() => exportTable('print')} className="btn btn-primary btn-sm m-1">
                            <IconPrinter className="ltr:mr-2 rtl:ml-2" />
                            {t("PRINT")}
                        </button>
                    </div>
                }

                <div className="ltr:ml-auto rtl:mr-auto">
                    <input type="text" className="form-input w-auto" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            <div className="datatables">
                {
                    loading ? (<div className="flex justify-center items-center h-full">
                        <span className="animate-spin border-4 border-blue-500 border-l-transparent rounded-full w-12 h-12 inline-block align-middle m-auto mb-10"></span>
                    </div>) : (<DataTable highlightOnHover className="whitespace-nowrap text-black font-bold"
                        records={recordsData} columns={columns} totalRecords={initialRecords.length}
                        recordsPerPage={pageSize} page={page} onPageChange={(p) => setPage(p)}
                        recordsPerPageOptions={PAGE_SIZES} onRecordsPerPageChange={setPageSize}
                        sortStatus={sortStatus} onSortStatusChange={setSortStatus} minHeight={200}
                        paginationText={({ from, to, totalRecords }) =>
                            t('PAGINATION_TEXT', { from, to, totalRecords })
                        }
                        styles={{
                            header: {
                                backgroundColor: "#1554A2",
                                color: "white",
                                zIndex:0
                            }
                        }}
                        style={{
                            fontWeight: 'bold',
                        }}

                    />)
                }
            </div>
        </div>);
};
export default MultiColumn;
