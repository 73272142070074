import React, { useContext } from 'react'
import LineupTable from '../DataTables/LineupTable'
import { MobileContext } from '../../context/isMobileContext'

export default function Overview({ events, match }) {
    const isMobile=useContext(MobileContext)

    return (
        <div className='my-5'>
            <div className={`flex justify-center ${isMobile?"flex-wrap":""}`}>
                <div className={`mx-2 ${isMobile?"flex-wrap w-full":"w-1/2"}`}>
                    <LineupTable players={events[1]?.ListPlayer || []}
                        substitutes={events[1]?.ListReplacment || []}
                        team={match?.equipeHome?.id == events[1]?.equipe_id ? match.equipeHome : match.equipeAway} />
                </div>
                <div className={`mx-2 ${isMobile?" flex-wrap w-full":"w-1/2"}`}>
                    <LineupTable players={events[0]?.ListPlayer || []}
                        substitutes={events[0]?.ListReplacment || []}
                        team={match?.equipeHome?.id == events[0]?.equipe_id ? match.equipeHome : match.equipeAway} />
                </div>
            </div>

        </div>
    )
}