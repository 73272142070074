import React from "react";
import { useTranslation } from "react-i18next";

export default function SmallLoading() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center space-x-2">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      <span className="text-blue-500 font-medium">{t("LOADING")}...</span>
    </div>
  );
}
