import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { customOption, divisionsData, roundData } from '../../utils/selectesData';
import { createMatch, updateMatch } from '../../service/matcheService';
import { enqueueSnackbar } from 'notistack';

export default function ModalFormMatch({ setSelectedMatch, SelectedMatch, loadListOfMatches, modal20, setModal20, arbiters, clubs, stades, competitions }) {
    const { t } = useTranslation();

    const [formState, setFormState] = useState({
        competition: null,
        date: null,
        homeTeam: null,
        awayTeam: null,
        division: null,
        stadium: null,
        officeReferee: null,
        assistantReferee1: null,
        assistantReferee2: null,
        assistantReferee3: null,
        round: null
    });

    useEffect(() => {
        if (SelectedMatch) {
            setFormState({
                competition: competitions.find(option => option.value == SelectedMatch?.competition?.id) || null,
                date: SelectedMatch?.date || null,
                homeTeam: clubs.find(option => option.value == SelectedMatch?.equipeHome?.id) || null,
                awayTeam: clubs.find(option => option.value == SelectedMatch?.equipeAway?.id) || null,
                division: SelectedMatch?.category_divisions ? { value: SelectedMatch?.category_divisions, label: SelectedMatch?.category_divisions } : null,
                stadium: stades.find(option => option.value == SelectedMatch?.stade?.id) || null,
                officeReferee: arbiters?.find(options => options.value == SelectedMatch?.arbiter.find(ref => ref.function === "Arbitre")?.id) || null,
                assistantReferee1: arbiters?.find(options => options.value == SelectedMatch?.arbiter.find(ref => ref.function === "Arbitre Assistant 1")?.id) || null,
                assistantReferee2: arbiters?.find(options => options.value == SelectedMatch?.arbiter.find(ref => ref.function === "Aribtre Assistant 2")?.id) || null,
                assistantReferee3: arbiters?.find(options => options.value == SelectedMatch?.arbiter.find(ref => ref.function === "4 éme arbitre")?.id) || null,
                round: SelectedMatch?.round ? { value: SelectedMatch?.round, label: SelectedMatch?.round } : null
            });
        }
    }, [SelectedMatch, modal20]);

    const resetFormState = () => {
        setSelectedMatch(null);
        setFormState({
            competition: null,
            date: null,
            homeTeam: null,
            awayTeam: null,
            division: null,
            stadium: null,
            officeReferee: null,
            assistantReferee1: null,
            assistantReferee2: null,
            assistantReferee3: null,
            round: null
        });
    };

    const [error, setError] = useState(null);

    const handleSelectChange = (selectedOption, actionMeta) => {
        setError(null);
        setFormState(prevState => ({
            ...prevState,
            [actionMeta.name]: selectedOption
        }));
    };

    const handleDateChange = (date) => {
        setError(null);
        setFormState(prevState => ({
            ...prevState,
            date: date[0]
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { officeReferee,
            assistantReferee1,
            round,
            assistantReferee2,
            assistantReferee3,
            homeTeam,
            awayTeam,
            competition,
            date,
            division,
            stadium } = formState;

        if (!round || !competition || !division || !date || !stadium || !homeTeam || !awayTeam) {
            setError(t('FILL_ALL_FIELDS'));
            return;
        }
        // if (!officeReferee || !round || !competition || !division || !date || !stadium || !assistantReferee1 || !assistantReferee2 || !assistantReferee3 || !homeTeam || !awayTeam) {
        //     setError(t('FILL_ALL_FIELDS'));
        //     return;
        // }

        if (homeTeam.value === awayTeam.value) {
            setError(t('HOME_AWAY_TEAM_DUPLICATE_ERROR'));
            return;
        }
        // if (
        //     officeReferee?.value === assistantReferee1?.value || officeReferee?.value === assistantReferee2?.value || officeReferee?.value === assistantReferee3?.value ||
        //     assistantReferee1?.value === assistantReferee2?.value || assistantReferee1?.value === assistantReferee3?.value ||
        //     assistantReferee2?.value === assistantReferee3?.value) {
        //     setError(t('REFEREE_DUPLICATE_ERROR'));
        //     return;
        // }

        const formattedDate = (typeof date === "string" && /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(date))
            ? new Date(new Date(date.replace(" ", "T")))
            : date instanceof Date
                ? new Date(date)
                : null;
        const hours = formattedDate.getHours();
        const minutes = formattedDate.getMinutes();
        const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        
        const dataTosend = {
            category_divisions: division?.value,
            competition_id: competition?.value,
            equipe_away_id: awayTeam?.value,
            equipe_home_id: homeTeam?.value,
            match_date: formattedDate ? formattedDate.toISOString().split('T')[0] : null,
            match_time: formattedDate ? timeString : null,
            stade_id: stadium.value,
            round: round?.value,
            arbitres: []
        };
        if (officeReferee || assistantReferee1 || assistantReferee2 || assistantReferee3) {
            dataTosend.arbitres = [
                officeReferee ? { id: officeReferee.value, function: 'Arbitre' } : null,
                assistantReferee1 ? { id: assistantReferee1.value, function: 'Arbitre Assistant 1' } : null,
                assistantReferee2 ? { id: assistantReferee2.value, function: 'Aribtre Assistant 2' } : null,
                assistantReferee3 ? { id: assistantReferee3.value, function: '4 éme arbitre' } : null
            ].filter(referee => referee !== null);
        }
        setError(null);
        try {
            if (SelectedMatch == null) {
                await createMatch(dataTosend);
                enqueueSnackbar(t('MATCH_CREATED_SUCCESS'), { variant: 'success' });
            }
            if (SelectedMatch != null) {
                await updateMatch(SelectedMatch.id, dataTosend);
                enqueueSnackbar(t('MATCH_UPDATED_SUCCESS'), { variant: 'success' });
            }
            setModal20(false);
            setSelectedMatch(null);
            loadListOfMatches(true);
        } catch (e) {
            SelectedMatch != null ? enqueueSnackbar(t('MATCH_ERROR_UPDATED'), { variant: 'error' }) : enqueueSnackbar(t('MATCH_ERROR_CREATED'), { variant: 'error' });
        }
    };

    return (
        <Transition appear show={modal20} as={Fragment}>
            <Dialog as="div" open={modal20} onClose={() => { setModal20(false); resetFormState() }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-scroll">
                    <div className="flex items-start justify-center min-h-screen px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel border-0 p-0 rounded-lg w-full max-w-5xl my-8 text-black dark:text-white-dark">
                                <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                    <h5 className="font-bold text-lg">
                                        {SelectedMatch != null ? t("UPDATE_MATCH") : t("CREATE_MATCH")}
                                    </h5>
                                    <button onClick={() => { setModal20(false); resetFormState() }} type="button" className="text-white-dark hover:text-dark">
                                        X
                                    </button>
                                </div>
                                <div className="p-5">
                                    <form className="space-y-5" onSubmit={handleSubmit}>
                                        {error && <div className="relative flex items-center border p-3.5 rounded before:inline-block before:absolute before:top-1/2 ltr:before:right-0 rtl:before:left-0 rtl:before:rotate-180 before:-mt-2 before:border-r-8 before:border-t-8 before:border-b-8 before:border-t-transparent before:border-b-transparent before:border-r-inherit text-danger bg-danger-light border-danger ltr:border-r-[64px] rtl:border-l-[64px] dark:bg-danger-dark-light">
                                            <span className="px-4"><strong className="mx-4">{t("MESSAGES_ERROR.ERROR")}</strong>{error}</span>
                                        </div>}
                                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
                                            <div>
                                                <label htmlFor="date">{t("SELECT_DATE_LABEL")} <span className='text-red-500'>*</span></label>
                                                <Flatpickr
                                                    name="date"
                                                    value={formState.date}
                                                    placeholder={t('SELECT_DATE_LABEL')}
                                                    options={
                                                        {
                                                            enableTime: true,
                                                            dateFormat: "Y-m-d H:i",
                                                        }
                                                    }
                                                    className="form-input rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleDateChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="competition">{t("SELECT_COMPETITION_LABEL")}<span className='text-red-500'>*</span></label>
                                                <Select
                                                    name="competition"
                                                    placeholder={t("SELECT_COMPETITION_LABEL")}
                                                    options={competitions}
                                                    className="rounded-md shadow-sm z-auto focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.competition}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="round">{t("SELECT_ROUND_LABEL")}<span className='text-red-500'>*</span></label>
                                                <Select
                                                    name="round"
                                                    placeholder={t("SELECT_ROUND_LABEL")}
                                                    options={roundData}
                                                    className="rounded-md shadow-sm z-auto focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    value={formState.round}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="homeTeam">{t("SELECT_TEAM_HOME_LABEL")} <span className='text-red-500'>*</span></label>
                                                <Select
                                                    name="homeTeam"
                                                    placeholder={t("SELECT_TEAM_HOME_LABEL")}
                                                    options={clubs}
                                                    className="rounded-md shadow-sm z-auto focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.homeTeam}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="awayTeam">{t("SELECT_TEAM_AWAY_LABEL")} <span className='text-red-500'>*</span></label>
                                                <Select
                                                    name="awayTeam"
                                                    placeholder={t("SELECT_TEAM_AWAY_LABEL")}
                                                    options={clubs}
                                                    className="rounded-md shadow-sm z-auto focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.awayTeam}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="division">{t("SELECT_COMPETITIVE_DIVISION_LABEL")} <span className='text-red-500'>*</span></label>
                                                <Select
                                                    name="division"
                                                    placeholder={t("SELECT_COMPETITIVE_DIVISION_LABEL")}
                                                    options={divisionsData}
                                                    className="rounded-md shadow-sm z-96 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    value={formState.division}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="stadium">{t("SELECT_STADIUM_LABEL")} <span className='text-red-500'>*</span></label>
                                                <Select
                                                    name="stadium"
                                                    placeholder={t("SELECT_STADIUM_LABEL")}
                                                    options={stades}
                                                    className="rounded-md shadow-sm z-96 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    value={formState.stadium}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="officeReferee">{t("SELECT_OFFICE_REFEREE_LABEL")}</label>
                                                <Select
                                                    name="officeReferee"
                                                    placeholder={t("SELECT_OFFICE_REFEREE_LABEL")}
                                                    options={arbiters}
                                                    className="rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.officeReferee}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="assistantReferee1">{t("SELECT_ASSISTANT1_REFEREE_LABEL")}</label>
                                                <Select
                                                    name="assistantReferee1"
                                                    placeholder={t("SELECT_ASSISTANT1_REFEREE_LABEL")}
                                                    options={arbiters}
                                                    className="rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.assistantReferee1}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="assistantReferee2">{t("SELECT_ASSISTANT2_REFEREE_LABEL")}</label>
                                                <Select
                                                    name="assistantReferee2"
                                                    placeholder={t("SELECT_ASSISTANT2_REFEREE_LABEL")}
                                                    options={arbiters}
                                                    className="rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.assistantReferee2}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="assistantReferee3">{t("SELECT_ASSISTANT3_REFEREE_LABEL")}</label>
                                                <Select
                                                    name="assistantReferee3"
                                                    placeholder={t("SELECT_ASSISTANT3_REFEREE_LABEL")}
                                                    options={arbiters}
                                                    className="rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                                    onChange={handleSelectChange}
                                                    components={{ Option: customOption }}
                                                    value={formState.assistantReferee3}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className={`btn text-right !mt-6 ${SelectedMatch == null ? "btn-primary" : "bg-green-600 text-white"}`}>
                                            {SelectedMatch == null ? t("REGISTER") : t("UPDATE")}
                                        </button>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
