import { Switch } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { addEventToState } from "../../../redux/actions/matchEventsActions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { AuthContext } from "../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { MobileContext } from "../../../backoffice/src/context/isMobileContext";
import ModalSelectPlayerMobile from "../../../backoffice/src/components/Modal/ModalSelectPlayerMobile";
import { Typography, Card, CardContent, Button } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";

const MatchEvent = ({
  t,
  match,
  setScoore,
  Scoore,
  currentTeam,
  teamRecevant,
  teamVisiteuse,
  prolongations,
  setProlongations,
  penaltyHomeScore,
  penaltyAwayScore,
  setPenaltyHomeScore,
  setPenaltyAwayScore,
  tirsAuBut,
  setTirsAuBut,
  players,
}) => {
  const dispatch = useDispatch();
  const [matchNonJoue, setMatchNonJoue] = useState(false);
  const [matchArrete, setMatchArrete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext);
  const matchHasArtbiter = useSelector(
    (state) => state.matchReducer.matchHasArtbiter
  );
  const [finalHomeScore, setFinalHomeScore] = useState(
    Scoore?.equipeHomeScoore
  );
  const [finalAwayScore, setFinalAwayScore] = useState(
    Scoore?.equipeAwayScoore
  );
  const [extraTimeHomeScore, setExtraTimeHomeScore] = useState(0);
  const [extraTimeAwayScore, setExtraTimeAwayScore] = useState(0);
  const isMobile = useContext(MobileContext);
  const [periode1, setPeriode1] = useState(0);
  const [periode2, setPeriode2] = useState(0);
  const [prolongation1, setProlongation1] = useState(0);
  const [prolongation2, setProlongation2] = useState(0);
  const [stopped_at_minute, setStopped_at_minute] = useState(0);
  const [openModalSelectPlayer, setopenModalSelectPlayer] = useState(false);
  const events = useSelector((state) => state.matchEventsReducer.events);
  const [ManOfMatch, setManOfMatch] = useState(
    events.find((event) => event.eventName === "match_stats")?.man_of_match ||
      null
  );

  useEffect(() => {
    if (
      !isNaN(finalHomeScore) &&
      !isNaN(finalAwayScore) &&
      !isNaN(extraTimeAwayScore) &&
      !isNaN(extraTimeHomeScore)
    ) {
      setScoore({
        equipeAwayScoore:
          extraTimeAwayScore != 0
            ? finalAwayScore + extraTimeAwayScore
            : finalAwayScore,
        equipeHomeScoore:
          extraTimeHomeScore != 0
            ? finalHomeScore + extraTimeHomeScore
            : finalHomeScore,
      });
    }
  }, [finalHomeScore, finalAwayScore, extraTimeAwayScore, extraTimeHomeScore]);

  const equipeHomeId = match?.match?.equipeHome.id;
  const equipeAwayId = match?.match?.equipeAway.id;

  const handleValidate = () => {
    let matchEventData;
    if (matchNonJoue || matchArrete) {
      matchEventData = {
        eventName: "match_stats",
        status: matchNonJoue ? "Not played At All" : "stooped",
        stopped_at_minute: stopped_at_minute,
      };
    } else {
      matchEventData = {
        eventName: "match_stats",
        status: tirsAuBut
          ? "penalties"
          : matchNonJoue
          ? "Not played At All"
          : matchArrete
          ? "stooped"
          : prolongations
          ? "extra_time"
          : "played",

        match_stats: {
          first_time: periode1,
          penalty_shootout_scores: {
            [equipeHomeId]: penaltyHomeScore,
            [equipeAwayId]: penaltyAwayScore,
          },
          second_time: periode2,
          first_extra_time: prolongation1,
          second_extra_time: prolongation2,
        },
        man_of_match: ManOfMatch,
      };
    }

    dispatch(addEventToState(matchEventData));
    enqueueSnackbar(t("MATCH_VALID_SUCCESS"), { variant: "success" });
  };

  return (
    <div className="border border-gray-300 rounded-lg">
      <div className="flex items-end justify-between p-4 bg-gray-100 rounded-lg shadow-lg space-y-4">
        <div className="flex flex-wrap items-center space-x-4">
          <div className={`p-2 font-myteam flex items-center`}>
            <input
              type="checkbox"
              name="prolongations"
              id="prolongations"
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              checked={prolongations}
              onChange={(e) => setProlongations(e.target.checked)}
              disabled={matchNonJoue || matchArrete}
            />
            <label
              htmlFor="prolongations"
              className="mx-2 text-sm font-myteam font-medium text-gray-700"
            >
              {t("PROLONGATIONS")}
            </label>
          </div>
          <div className={`p-2 flex items-center`}>
            <input
              type="checkbox"
              name="tirs-au-but"
              id="tirs-au-but"
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              checked={tirsAuBut}
              disabled={matchNonJoue || matchArrete}
              onChange={(e) => setTirsAuBut(e.target.checked)}
            />
            <label
              htmlFor="tirs-au-but"
              className="mx-2 text-sm font-myteam font-medium text-gray-700"
            >
              {t("TIRS_AU_BUT")}
            </label>
          </div>
          <div className={`p-2 flex items-center`}>
            <input
              type="checkbox"
              name="status"
              id="match-non-joue"
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              checked={matchNonJoue}
              onChange={(e) => {
                setMatchArrete(false);
                setMatchNonJoue(e.target.checked);
              }}
            />
            <label
              htmlFor="match-non-joue"
              className="mx-2 text-sm font-myteam font-medium text-gray-700"
            >
              {t("MATCH_NON_JOUE")}
            </label>
          </div>
          <div className={`p-2 flex items-center`}>
            <input
              type="checkbox"
              name="status"
              id="match-arrete"
              className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              checked={matchArrete}
              onChange={(e) => {
                setMatchNonJoue(false);
                setMatchArrete(e.target.checked);
              }}
            />
            <label
              htmlFor="match-arrete"
              className="mx-2 text-sm font-myteam font-medium text-gray-700"
            >
              {t("MATCH_ARRETE")}
            </label>
          </div>
        </div>
      </div>

      {!matchNonJoue && (
        <>
          <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
            {matchArrete && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3 my-2">
                <div>
                  <label className="text-lg font-myteam font-bold mb-4">
                    {t("STOPED_TIME")}
                  </label>
                  <input
                    type="number"
                    value={stopped_at_minute}
                    min={0}
                    max={120}
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                    onChange={(e) =>
                      setStopped_at_minute(parseInt(e.target.value))
                    }
                    className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            )}

            <div className="my-4 max-w-7xl mx-auto">
              <h1 className="text-lg font-myteam font-bold mb-4">
                {t("MAN_OF_MATCH")}
              </h1>
              <Card className="bg-white shadow-md rounded-lg overflow-hidden">
                <CardContent className="p-4 sm:p-6">
                  <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                    <Button
                      variant="outlined"
                      startIcon={<PersonAdd />}
                      onClick={() => setopenModalSelectPlayer(true)}
                      className="mb-4 sm:mb-0 text-blue-600 border-blue-600 hover:bg-blue-50 w-full sm:w-auto"
                    >
                      {t("SELECT_PLAYER")}
                    </Button>
                    {!ManOfMatch && (
                      <Typography
                        variant="body1"
                        className="text-gray-600 italic text-center sm:text-right"
                      >
                        {t("NO_PLAYER_SELECTED")}
                      </Typography>
                    )}
                  </div>
                  {ManOfMatch && (
                    <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6">
                      <img
                        src={ManOfMatch.image}
                        alt={ManOfMatch.fullName}
                        className="w-16 h-16 sm:w-24 sm:h-24 rounded-full object-cover"
                      />
                      <div className="text-center sm:text-left">
                        <Typography variant="h6" className="text-gray-800">
                          {ManOfMatch.fullName}
                        </Typography>
                        <Typography variant="body2" className="text-gray-600">
                          {t("SHIRT_NUMBER")}: {ManOfMatch.shirtNmbr}
                        </Typography>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>

            <h1 className="text-lg font-myteam font-bold mb-4">
              {t("RESULTATS")}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
              <div className="border border-gray-300 p-4 rounded-lg bg-white shadow">
                <p className="font-myteam font-semibold mb-2">{t("FINAL")}</p>
                <div className="mb-2">
                  <label className="block text-sm font-myteam font-medium text-gray-700">
                    {match?.match?.equipeHome?.slug}
                  </label>
                  <input
                    type="number"
                    value={finalHomeScore}
                    min={0}
                    max={50}
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                    readOnly
                    onChange={(e) =>
                      setFinalHomeScore(parseInt(e.target.value))
                    }
                    className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-myteam font-medium text-gray-700">
                    {match?.match?.equipeAway?.slug}
                  </label>
                  <input
                    type="number"
                    value={finalAwayScore}
                    min={0}
                    max={50}
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                    readOnly
                    onChange={(e) =>
                      setFinalAwayScore(parseInt(e.target.value))
                    }
                    className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              {/* {prolongations && (
                <div className="border border-gray-300 p-4 rounded-lg bg-white shadow">
                  <p className="font-myteam font-semibold mb-2">
                    {t("APRES_LE_TEMPS_REGLEMENTAIRE")}
                  </p>
                  <div className="mb-2">
                    <label className="block text-sm font-myteam font-medium text-gray-700">
                      {match?.match?.equipeHome?.slug}
                    </label>
                    <input
                      type="number"
                      value={extraTimeHomeScore}
                      min={0}
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      max={50}
                      readOnly
                      onChange={(e) =>
                        setExtraTimeHomeScore(parseInt(e.target.value))
                      }
                      className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-myteam font-medium text-gray-700">
                      {match?.match?.equipeAway?.slug}
                    </label>
                    <input
                      type="number"
                      value={extraTimeAwayScore}
                      min={0}
                      max={50}
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      readOnly
                      onChange={(e) =>
                        setExtraTimeAwayScore(parseInt(e.target.value))
                      }
                      className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
              )} */}
              {tirsAuBut && (
                <div className="border border-gray-300 p-4 rounded-lg bg-white shadow">
                  <p className="font-myteam font-semibold mb-2">
                    {t("TIRS_AU_BUT_LABEL")}
                  </p>
                  <div className="mb-2">
                    <label className="block text-sm font-myteam font-medium text-gray-700">
                      {match?.match?.equipeHome?.slug}
                    </label>
                    <input
                      type="number"
                      value={penaltyHomeScore}
                      min={0}
                      max={50}
                      disabled={
                        user?.equipe_id != match?.match?.equipeHome?.id &&
                        !matchHasArtbiter
                      }
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      onChange={(e) =>
                        setPenaltyHomeScore(parseInt(e.target.value))
                      }
                      className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-myteam font-medium text-gray-700">
                      {match?.match?.equipeAway?.slug}
                    </label>
                    <input
                      type="number"
                      value={penaltyAwayScore}
                      min={0}
                      disabled={
                        user?.equipe_id != match?.match?.equipeAway?.id &&
                        !matchHasArtbiter
                      }
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      max={50}
                      onChange={(e) =>
                        setPenaltyAwayScore(parseInt(e.target.value))
                      }
                      className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="px-6 pb-5 bg-gray-100 rounded-lg shadow-lg">
            <h1 className="text-lg font-myteam font-bold mb-4">{t("TIMES")}</h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-myteam font-medium text-gray-700">
                  {t("PERIODE_1")}
                </label>
                <input
                  type="number"
                  value={periode1}
                  min={0}
                  max={120}
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  onChange={(e) => setPeriode1(parseInt(e.target.value))}
                  className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-myteam font-medium text-gray-700">
                  {t("PERIODE_2")}
                </label>
                <input
                  type="number"
                  value={periode2}
                  min={0}
                  max={120}
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  onChange={(e) => setPeriode2(parseInt(e.target.value))}
                  className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {prolongations && !matchArrete && (
                <>
                  <div>
                    <label className="block text-sm  font-myteam font-medium text-gray-700">
                      {t("PROLONGATION_1")}
                    </label>
                    <input
                      type="number"
                      value={prolongation1}
                      min={0}
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      max={120}
                      onChange={(e) =>
                        setProlongation1(parseInt(e.target.value))
                      }
                      className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-myteam font-medium text-gray-700">
                      {t("PROLONGATION_2")}
                    </label>
                    <input
                      type="number"
                      value={prolongation2}
                      min={0}
                      max={120}
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      onChange={(e) =>
                        setProlongation2(parseInt(e.target.value))
                      }
                      className="mt-1 text-centern font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex justify-end my-2 w-auto">
              <button
                onClick={handleValidate}
                className="px-4 py-2 font-myteam bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
              >
                {t("VALIDER")}
              </button>
            </div>
          </div>
          {openModalSelectPlayer ? (
            <ModalSelectPlayerMobile
              players={players}
              callback={(ply) => {
                setManOfMatch(ply);
              }}
              isOpen={openModalSelectPlayer}
              onClose={() => setopenModalSelectPlayer(false)}
            />
          ) : null}
          {/* <div className="px-6 py-4 bg-gray-100 rounded-lg shadow-lg">
            <div>
              <h1 className="text-lg font-myteam font-bold text-left">
                {t("RESERVES")}
              </h1>
              <div className="flex flex-wrap items-center space-x-4">
                <div className="flex flex-wrap py-2 items-center">
                  <Switch disabled />
                  <label className="block text-sm font-myteam font-medium text-gray-700">
                    Obs, aprés match : NEANT
                  </label>
                </div>
                <div className="flex flex-wrap py-2 items-center">
                  <Switch disabled />
                  <label className="block text-sm font-myteam font-medium text-gray-700">
                    Réserves techniques : NEANT
                  </label>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default MatchEvent;
