import axiosInstance from "../../../api/axiosConfig";
import { logout } from "../../../api/services/authServices";

const getAllArbiters = async () => {
  let url = `licence/readAll-arbitre`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};

const getAllClubs = async () => {
  let url = `equipe/readAll`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    throw new Error(error);
  }
};

const getClassementsTeams = async (
  category = { category_divisions: "U17" }
) => {
  let url = `match/ranking`;
  try {
    return await axiosInstance.post(url, category);
  } catch (error) {
    throw new Error(error);
  }
};

const getAllStades = async () => {
  let url = `stade/readAll`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};

const getAllCompetition = async () => {
  let url = `competition/readAll`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const getAllMatches = async () => {
  let url = `match/fetchMatches?page=1&limit=1000`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const getSingleMatch = async (id) => {
  let url = `match/read-lineup/${id}`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};

const createMatch = async (payload) => {
  let url = `match/create`;
  try {
    return await axiosInstance.post(url, payload);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const updateMatch = async (matchId, payload) => {
  let url = `match/update/${matchId}`;
  try {
    return await axiosInstance.put(url, payload);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const deleteMatch = async (id) => {
  let url = `match/delete/${id}`;
  try {
    return await axiosInstance.delete(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};

export {
  getAllArbiters,
  deleteMatch,
  getAllClubs,
  getAllStades,
  getAllCompetition,
  createMatch,
  getAllMatches,
  getSingleMatch,
  updateMatch,
  getClassementsTeams
};
