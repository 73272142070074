export const ADD_EVENT = "ADD_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const SEND_SERVER_REQUEST = "SEND_SERVER_REQUEST";
export const SEND_SERVER_FAILED = "SEND_SERVER_FAILED";
export const SEND_SERVER_SUCCESS = "SEND_SERVER_SUCCESS";
export const ADD_EVENT_TO_STATE = 'ADD_EVENT_TO_STATE';
export const REMOVE_EVENT_FROM_STATE = 'REMOVE_EVENT_FROM_STATE';
export const REMOVE_ENVENTS = 'REMOVE_ENVENTS';


export const addEventToState = (event) => ({
  type: ADD_EVENT_TO_STATE,
  payload: event,
});

export const removeEventFromState = (event) => ({
  type: REMOVE_EVENT_FROM_STATE,
  payload: event,
});

export const addEvent = (eventData) => ({
  type: ADD_EVENT,
  payload: eventData,
});

export const deleteEvent = (event) => ({
  type: DELETE_EVENT,
  payload: event,
});

export const sendServerRequest = () => ({
  type: SEND_SERVER_REQUEST,
});

export const sendServerFailed = (error) => ({
  type: SEND_SERVER_FAILED,
  payload: error,
});

export const sendServerSuccess = () => ({
  type: SEND_SERVER_SUCCESS,
});
export const removeEvents = () => ({
  type: REMOVE_ENVENTS,
});
