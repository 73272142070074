import {
  fetchTeamComposition,
  getMatchesByUserId,
  getTeam,
  matchSignateur,
  updateTeamComposition,
  validateTeamComposition,
} from "../../api/services/matchServices";
import {
  fetchMatchesRequest,
  fetchMatchesSuccess,
  fetchMatchesFailure,
  fetchTeamRequest,
  fetchTeamSuccess,
  fetchTeamFailure,
  updateCompositionSuccess,
  updateCompositionRequest,
  updateCompositionFailure,
  fetchCompositionSuccess,
  saveCompositionInServerSuccess,
  validateCompositionRequest,
  validateCompositionFailure,
  validateCompositionSuccess,
} from "../actions/matchActions";

export const fetchMatches = () => async (dispatch) => {
  dispatch(fetchMatchesRequest());
  try {
    const matches = await getMatchesByUserId();
    dispatch(fetchMatchesSuccess(matches));
  } catch (error) {
    dispatch(fetchMatchesFailure(error.toString()));
  }
};

export const fetchTeam = (matchId,teamId) => async (dispatch) => {
  dispatch(fetchTeamRequest());
  try {
    const team = await getTeam(matchId);
    dispatch(fetchTeamSuccess(team));
  } catch (error) {
    dispatch(fetchTeamFailure(error.toString()));
  }
};

export const getComposition = (matchId, equipeId, type) => async (dispatch) => {
  dispatch(updateCompositionRequest());
  try {
    const compositionUpdated = await fetchTeamComposition(matchId, equipeId);
    if (type == "userTeam") {
      dispatch(updateCompositionSuccess(compositionUpdated));
    } else {
      dispatch(fetchCompositionSuccess(compositionUpdated));
    }
  } catch (error) {
    dispatch(updateCompositionFailure(error.toString()));
  }
};

export const updateComposition = (composition,dataToState) => async (dispatch) => {
  dispatch(updateCompositionRequest());
  try {
    await updateTeamComposition(composition);
    dataToState&&dispatch(updateCompositionSuccess(dataToState));
    dispatch(saveCompositionInServerSuccess());
  } catch (error) {
    dispatch(updateCompositionFailure(error.toString()));
  }
};
export const validateComposition = (matchId, equipeId,status) => async (dispatch) => {
  dispatch(validateCompositionRequest());
  try {
    const res = await validateTeamComposition(matchId, equipeId,status);
    dispatch(validateCompositionSuccess());
    return res;
  } catch (error) {
    dispatch(validateCompositionFailure(error.toString()));
  }
};
export const matchSignateurThunk = (signateurData) => async (dispatch) => {
  dispatch(validateCompositionRequest());
  try {
    const res = await matchSignateur(signateurData);
    dispatch(validateCompositionSuccess());
    return res;
  } catch (error) {
    dispatch(validateCompositionFailure(error.toString()));
  }
};
