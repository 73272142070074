import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import ShieldMoonIcon from "@mui/icons-material/ShieldMoon";
import { useDispatch } from "react-redux";
import { removeEventFromState } from "../../../redux/actions/matchEventsActions";
import DeleteIcon from "@mui/icons-material/Delete";
import { MobileContext } from "../../../backoffice/src/context/isMobileContext";

const HistoryEvent = ({ title, from }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const events = useSelector((state) => state.matchEventsReducer.events);
  const [sortedEvents, setSortedEvents] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const sorted = [...events].sort((a, b) => {
      return a.RegularTime - b.RegularTime;
    });
    setSortedEvents(sorted);
  }, [events]);

  const handleRemove = (event) => {
    dispatch(removeEventFromState(event));
  };

  return (
    <div className={`text-center bg-gray-100 ${isMobile ? "p-2 " : "p-4"}`}>
      <h2 className="text-xl font-bold mb-4">{t(title)}</h2>
      {sortedEvents.length === 0 ? (
        <p className="text-gray-500">{t("NO_EVENTS_FOUND")}</p>
      ) : (
        <div
          className={`space-y-4 ${"max-h-[500px]"} overflow-y-scroll ${
            isMobile ? " m-2" : " m-4"
          }`}
        >
          {sortedEvents.map((event, index) => {
            return (
              <div key={index} className="px-2 rounded-lg">
                {renderEventDetails(handleRemove, event, isMobile)}
                <hr />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const renderEventDetails = (callback, event, isMobile) => {
  switch (event.eventName) {
    case "subs":
      return (
        <div className="flex items-center justify-between">
          <div className="flex flex-wrap items-center ">
            <img src={event.team?.logo} className={`${isMobile ? "mr-1" : "mr-5"}w-10 h-10`} />
            <p>
              {event?.RegularTime}'
              {event?.extraTime !== 0 && `+${event?.extraTime}`}
            </p>
            <div className={`${isMobile?"":"flex items-center flex-wrap"}`}>
              <div className="font-myteam items-center ml-5">
                <p>{event.playerIn.fullName}</p>
              </div>
              <div className={`flex items-center ${isMobile?"justify-center p2":"p2"}`}>
                <img
                  src={`../../assets/images/subs.png`}
                  className="w-10 h-10"
                  alt="Red card"
                />
              </div>
              <div className="font-myteam items-center">
                <p>{event.playerOut.fullName}</p>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => callback(event)}
              className={`bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded ${
                isMobile ? "py-1 px-2" : "py-2 px-4"
              }`}
            >
              <DeleteIcon />
            </button>{" "}
          </div>
        </div>
      );
    case "goalScorer":
      return (
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <img
              src={event.team?.logo}
              className={`${isMobile ? "mr-1" : "mr-5"}w-10 h-10`}
            />
            <p>
              {event?.RegularTime}'
              {event?.extraTime !== 0 && `+${event?.extraTime}`}
            </p>
            <div className="flex flex-wrap">
              <div className="flex flex-wrap items-center">
                <div className="flex items-center p-2">
                  <SportsSoccerIcon />
                </div>
                <div className="flex font-myteam items-center">
                  <p>{event.goalScorer.fullName}</p>
                </div>
              </div>
              {event?.assistPlayer && (
                <div className="flex flex-wrap items-center">
                  <div className="flex items-center p-2">
                    <img
                      src="../../assets/images/assist.png"
                      className="w-7 h-7"
                    />
                  </div>
                  <div className="flex font-myteam items-center">
                    <p>{event.assistPlayer.fullName}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <button
              onClick={() => callback(event)}
              className={`bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded ${
                isMobile ? "py-1 px-2" : "py-2 px-4"
              }`}
            >
              <DeleteIcon />
            </button>{" "}
          </div>
        </div>
      );
    case "injury":
      return (
        <div className="flex items-center justify-between">
          <div className="flex flex-wrap items-center ">
            <img src={event.team?.logo} className={`${isMobile ? "mr-1" : "mr-5"}w-10 h-10`} />

            <p>
              {event?.RegularTime}'
              {event?.extraTime !== 0 && `+${event?.extraTime}`}
            </p>
            <div className="flex items-center p-2">
              <ShieldMoonIcon />
            </div>
            <div className="flex font-myteam items-center">
              <p>{event.injuredPlayer.fullName}</p>
            </div>
          </div>
          <div>
            <button
              onClick={() => callback(event)}
              className={`bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded ${
                isMobile ? "py-1 px-2" : "py-2 px-4"
              }`}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      );
    case "discipline":
      return (
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex flex-wrap items-center ">
            <img src={event.team?.logo} className={`${isMobile ? "mr-1" : "mr-5"}w-10 h-10`} />

            <p>
              {event?.RegularTime}'
              {event?.extraTime !== 0 && `+${event?.extraTime}`}
            </p>
            <div className="flex items-center p-2">
              <img
                src={`../../assets/images/footCards/${event?.cardDiscipline}.png`}
                className="w-6 h-8"
                alt="Red card"
              />
            </div>
            <div className="flex font-myteam items-center">
              <p>{event.pers[0].fullName}</p>
            </div>
          </div>
          <div>
            <button
              onClick={() => callback(event)}
              className={`bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white border border-red-500 hover:border-transparent rounded ${
                isMobile ? "py-1 px-2" : "py-2 px-4"
              }`}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default HistoryEvent;
