import React, { useContext, useState } from 'react'
import "../soccer/imports";
import ClassementPage from './ClassementTeams/ClassementPage';
import TeamStats from '../components/Statistics/TeamStats';
import LastResults from '../components/Statistics/LastResults';
import TeamGoalScorers from '../components/Statistics/TeamGoalScorers';
import PointsHistory from '../components/Statistics/charts/PointsHistory';
import GamesPointsSoccer from '../components/Statistics/charts/GamesPointsSoccer';
import { useUserRole } from '../context/UserRoleContext';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { divisionsData } from '../utils/selectesData';
import { MobileContext } from '../context/isMobileContext';


export default function DashboardPage() {
    const { t } = useTranslation();
    const { role } = useUserRole();
    const isMobile = useContext(MobileContext)
    const [selectedDivsCategory, setSelectedDivsCategory] = useState({ value: 'U13', label: "U13" });

    return (
        role != "club" ?
            <ClassementPage /> :
            <div>
                <div className='flex flex-wrap items-center justify-end'>
                    <h5>{t('CATEGORY_DIVISIONS')}</h5>
                    :
                    <Select
                        placeholder={t("FILTER_BY_CATRGORY")}
                        options={divisionsData}
                        className="p-2 rounded-md w-64 shadow-sm z-10 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        value={selectedDivsCategory}
                        onChange={(selectedOption) => setSelectedDivsCategory(selectedOption)}
                    />
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4`}>
                    <TeamStats category={selectedDivsCategory?.value} />
                    <LastResults category={selectedDivsCategory?.value} />
                    <TeamGoalScorers category={selectedDivsCategory?.value} />
                </div>
                <div className="flex flex-wrap justify-between">
                    <div className={`card ${isMobile ? "w-full" : "w-3/5"}`}>
                        <PointsHistory category={selectedDivsCategory?.value} />
                    </div>
                    <div className={`card ${isMobile ?"w-full":"w-2/5 vml-3"}`}>
                        <GamesPointsSoccer category={selectedDivsCategory?.value} />
                    </div>
                </div>
            </div>

    )
}
