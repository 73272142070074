import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { saveAs } from 'file-saver';
import { refusRegistrations, validateRegistrationsClub } from '../service/registrationService';
import { useSnackbar } from 'notistack';
import ModalCommentLicence from '../components/Modal/ModalCommentLicence';
import { AuthContext } from '../../../contexts/AuthContext';
import { CalendarMonth, ContactMail, Contacts, EmailOutlined, FolderShared, PersonPin, PrivacyTip, Public, Wc } from '@mui/icons-material';
import { Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { MobileContext } from '../context/isMobileContext';



export default function ProfilePage() {
    const { t } = useTranslation()
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const playerData = location.state?.playerData;
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const [active2, setActive2] = useState('1');
    const togglePara2 = (value) => {
        setActive2((oldValue) => {
            return oldValue === value ? '' : value;
        });
    };
    const [modal1, setModal1] = useState(false);
    const [comment, setComment] = useState('');
    const isMobile = useContext(MobileContext)

    const handleDownload = async (url, filename) => {
        try {
            console.log('Downloading from URL:', url);
            console.log('Expected filename:', filename);

            // Verify the URL by opening it in a new tab
            window.open(url, '_blank');

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            console.log('Blob downloaded:', blob);

            saveAs(blob, filename);
        } catch (error) {
            console.error('Error downloading the image:', error);
            alert('Failed to download the image. Please check the console for more details.');
        }
    };

    const handleAccept = async (playerId) => {
        try {
            const validate = await validateRegistrationsClub(playerId);
            if (validate?.status === 200) {
                navigate(-1)
                enqueueSnackbar(t('LICENCE_VALIDATE_SUCCESS'), { variant: 'success' });
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleRefuse = async () => {
        try {
            const response = await refusRegistrations(playerData?.id, comment);
            if (response?.status === 200) {
                navigate(-1)
                enqueueSnackbar(t('LICENCE_REFUSE_SUCCESS'), { variant: 'success' });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const renderFileContent = (fileUrl) => {
        const fileType = fileUrl?.split('.').pop().toLowerCase();
        if (fileType == 'pdf') {
            return (
                <div className="pdf-viewer w-72 h-72 flex items-center justify-center">
                    <Button
                        variant="contained"
                        color="primary"
                        href={fileUrl}
                        target="_blank"
                        startIcon={<PictureAsPdfIcon />}
                        style={{ width: 'fit-content' }}
                    >
                        {t('BTN_PDF_VIWER')}
                    </Button>
                </div>
            );
        }
        return (
            <img
                src={fileUrl}
                alt=""
                className="w-72 h-72"
            />
        );
    };

    return (
        <div className={`container mx-auto ${isMobile ? "py-2" : "py-8"}`}>
            <div className={`grid grid-cols-4 sm:grid-cols-12 gap-6 ${isMobile ? "px-1" : "px-4"}`}>
                <div className="col-span-4 sm:col-span-3">
                    <div className="bg-white shadow rounded-lg p-6">
                        <div className='flex flex-row justify-end items-center'>
                            {playerData?.status == "en cours" &&
                                <span className="badge bg-warning">{t("EN_COURS")}</span>
                            }
                            {playerData?.status == "brouillon" &&
                                <span className="badge bg-primary">{t("BROUILLON")}</span>
                            }
                            {playerData?.status == "refus" &&
                                <span className="badge bg-red-600">{t("REFUSER")}</span>
                            }
                            {playerData?.licence?.status == "validee" &&
                                <span className="badge bg-green-600">{t("VALIDE")}</span>
                            }
                        </div>
                        <div className="flex flex-col items-center">
                            <img
                                src={playerData?.photoProfil || playerData?.licence?.photoProfil || "../../../assets/images/profil-default.jpg"}
                                className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0" style={{ objectFit: "contain" }}
                            />
                            <h1 className="text-xl font-bold">{playerData?.prenom} {playerData?.nom}</h1>
                            <p className="text-gray-700">{playerData?.role ? playerData?.role : playerData?.licence?.role ? playerData?.licence?.role : "-"}</p>
                        </div>
                        {
                            playerData?.licence?.licenceId && <div className='flex justify-center'>
                                <h1 className='badge rounded w-max font-bold py-2 px-4 bg-orange-500 text-white'>{playerData?.licence?.licenceId}</h1>
                            </div>
                        }
                        <hr className="my-4 border-t border-gray-300" />
                        <div className="flex flex-col">
                            <span className="text-gray-700 uppercase font-bold tracking-wider text-center mb-2">{t("GENERALE_INFO")}</span>
                            <hr className="mb-4 border-t border-gray-300" />
                            <ul>
                                <li className="my-3"><span className='font-semibold text-black items-center'><EmailOutlined className="text-md" /> {t('EMAIL')} :</span> {playerData?.email ? playerData?.email : playerData?.licence?.email ? playerData?.licence?.email : "-"}</li>
                                <li className="my-3"><span className='font-semibold text-black items-center'><CalendarMonth className="text-md" /> {t('BIRTHDAY')} :</span> {playerData?.date_naissance ? playerData?.date_naissance : playerData?.licence?.date_naissance ? playerData?.licence?.date_naissance : "-"}</li>
                                <li className="my-3"><span className='font-semibold text-black items-center'><Wc className="text-md" /> {t("SEXE")} :</span> {playerData?.sexe ? playerData?.sexe : playerData?.licence?.sexe ? playerData?.licence?.sexe : "-"}</li>
                                <li className="my-3"><span className='font-semibold text-black items-center'><Public className="text-md" /> {t('FIRST_NATIONALITE')} :</span> {playerData?.nationPrimary ? playerData?.nationPrimary : playerData?.licence?.nationPrimary ? playerData?.licence?.nationPrimary : "-"}</li>
                                <li className="my-3"><span className='font-semibold text-black items-center'><PersonPin className="text-md" />{t("COMPETITIVE_DIVISIONS")} :</span> {playerData?.categoryDivisions ? playerData?.categoryDivisions : playerData?.licence?.categoryDivisions ? playerData?.licence?.categoryDivisions : "-"}</li>
                            </ul>
                        </div>
                        {
                            (["dtn"].includes(user?.role) && playerData?.licence?.status != "validee") && (
                                <div className="mt-6 flex flex-wrap gap-4 justify-center">
                                    <button className=" bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded" onClick={() => setModal1(true)}>{t("REFUSER")}</button>
                                    <button className="bg-green-600 hover:bg-green-800 text-white py-2 px-4 rounded" onClick={() => handleAccept(playerData?.id)}>{t("ACCEPT")}</button>
                                </div>
                            )
                        }
                    </div>
                </div>
                <ModalCommentLicence
                    t={t}
                    modal1={["dtn"].includes(user?.role) ? modal1 : false}
                    setModal1={setModal1}
                    comment={comment}
                    setComment={setComment}
                    handleSubmitRefuse={handleRefuse}
                />
                <div className="col-span-4 sm:col-span-9">
                    <div className={`bg-white shadow-md rounded-lg h-[100%] ${isMobile ? "p-1" : "p-6"}`}>
                        <h1 className={`font-bold mb-4 ${isMobile ? " text-center text-xl" : "text-2xl "}`}>{t('INFO_D_INSCRIPTION')}</h1>
                        <div className="mb-5">
                            <div className="space-y-2 font-semibold">
                                {
                                    playerData?.status == "refus" &&
                                    <div className="border border-[#d3d3d3] rounded dark:border-[#1b2e4b]">
                                        <button
                                            type="button"
                                            className={`p-4 w-full flex items-center bg-red-400 text-white`}
                                            onClick={() => togglePara2('0')}
                                        >
                                            <PrivacyTip className='text-md mx-4' />{t('MODAL_REFUSE_TITLE')}
                                            <div className={`ltr:ml-auto rtl:mr-auto `}>
                                                {active2 == "0" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                            </div>
                                        </button>
                                        <div>
                                            <AnimateHeight duration={300} height={active2 === '0' ? 'auto' : 0}>
                                                <div className="p-4 text-white-dark text-[13px] border-t border-[#d3d3d3] dark:border-[#1b2e4b]">
                                                    <ul className={`grid ${isMobile ? "grid-cols-1 gap-2" : "grid-cols-2 gap-4"}`}>
                                                        <li className="flex justify-between">
                                                            <span className="text-black font-bold">{t("COMMENT")} :</span>
                                                            <span>{playerData?.commentaire ? playerData?.commentaire : playerData?.licence?.commentaire ? playerData?.licence?.commentaire : "-"}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </AnimateHeight>
                                        </div>
                                    </div>
                                }
                                <div className="border border-[#d3d3d3] rounded dark:border-[#1b2e4b]">
                                    <button
                                        type="button"
                                        className={`p-4 w-full flex items-center bg-[#1554A2] text-white`}
                                        onClick={() => togglePara2('1')}
                                    >
                                        <PrivacyTip className='text-md mx-4' />{t('DETAILS_PERSONNELS')}
                                        <div className={`ltr:ml-auto rtl:mr-auto `}>
                                            {active2 == "1" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </div>
                                    </button>
                                    <div>
                                        <AnimateHeight duration={300} height={active2 === '1' ? 'auto' : 0}>
                                            <div className="p-4 text-white-dark text-[13px] border-t border-[#d3d3d3] dark:border-[#1b2e4b]">
                                                <ul className={`grid ${isMobile ? "grid-cols-1 gap-2" : "grid-cols-2 gap-4"}`}>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("LAST_NAME")} :</span>
                                                        <span>{playerData?.nom ? playerData?.nom : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("FIRST_NAME")} :</span>
                                                        <span>{playerData?.prenom ? playerData?.prenom : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("BIRTHDAY")} :</span>
                                                        <span>{playerData?.date_naissance ? playerData?.date_naissance : playerData?.licence?.date_naissance ? playerData?.licence?.date_naissance : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("CIN")} :</span>
                                                        <span>{playerData?.CIN_tuteur_legal ? playerData?.CIN_tuteur_legal : playerData?.licence?.CIN_tuteur_legal ? playerData?.licence?.CIN_tuteur_legal : playerData?.licence?.CIN ? playerData?.licence?.CIN : playerData?.CIN ? playerData?.CIN : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("SEXE")} :</span>
                                                        <span>{playerData?.sexe ? playerData?.sexe : playerData?.licence?.sexe ? playerData?.licence?.sexe : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("FIRST_NATIONALITE")} :</span>
                                                        <span>{playerData?.nationPrimary ? playerData?.nationPrimary : playerData?.licence?.nationPrimary ? playerData?.licence?.nationPrimary : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("SECOND_NATIONALITE")} :</span>
                                                        <span>{playerData?.nationSecondary ? playerData?.nationSecondary : playerData?.licence?.nationSecondary ? playerData?.licence?.nationSecondary : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t('PAYS_NAISSANCE')} :</span>
                                                        <span>{playerData?.birthNation ? playerData?.birthNation : playerData?.licence?.birthNation ? playerData?.licence?.birthNation : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("REGION_NAISSANCE")} :</span>
                                                        <span>{playerData?.region ? playerData?.region : playerData?.licence?.region ? playerData?.licence?.region : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("CITY")} :</span>
                                                        <span>{playerData?.villeOfBirth ? playerData?.villeOfBirth : playerData?.licence?.villeOfBirth ? playerData?.licence?.villeOfBirth : "-"}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </AnimateHeight>
                                    </div>
                                </div>
                                <div className="border border-[#d3d3d3] dark:border-[#1b2e4b] rounded">
                                    <button
                                        type="button"
                                        className={`p-4 w-full flex items-center bg-[#1554A2] text-white `}
                                        onClick={() => togglePara2('2')}
                                    >
                                        <ContactMail className='text-md mx-4' />{" "}{t('COORDONNEES')}
                                        <div className={`ltr:ml-auto rtl:mr-auto `}>
                                            {active2 == "2" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </div>
                                    </button>
                                    <div>
                                        <AnimateHeight duration={300} height={active2 === '2' ? 'auto' : 0}>
                                            <div className="p-4 text-white-dark text-[13px] border-t border-[#d3d3d3] dark:border-[#1b2e4b]">
                                                <ul className={`grid ${isMobile ? "grid-cols-1 gap-2" : "grid-cols-2 gap-4"}`}>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("ADRESSE")} 1 :</span>
                                                        <span>{playerData?.adresse_1 ? playerData?.adresse_1 : playerData?.licence?.adresse_1 ? playerData?.licence?.adresse_1 : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("ADRESSE")} 2 :</span>
                                                        <span>{playerData?.adresse_2 ? playerData?.adresse_2 : playerData?.licence?.adresse_2 ? playerData?.licence?.adresse_2 : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("CITY")} :</span>
                                                        <span>{playerData?.ville ? playerData?.ville : playerData?.licence?.ville ? playerData?.licence?.ville : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("ETAT")} :</span>
                                                        <span>{playerData?.etat ? playerData?.etat : playerData?.licence?.etat ? playerData?.licence?.etat : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("CODE_POSTAL")} :</span>
                                                        <span>{playerData?.codePostal ? playerData?.codePostal : playerData?.licence?.codePostal ? playerData?.licence?.codePostal : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("PAYS")} :</span>
                                                        <span>{playerData?.pays ? playerData?.pays : playerData?.licence?.pays ? playerData?.licence?.pays : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("PHONE_NUMBER")} :</span>
                                                        <span>{playerData?.numTel ? playerData?.numTel : playerData?.licence?.numTel ? playerData?.licence?.numTel : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t('EMAIL')} :</span>
                                                        <span>{playerData?.email ? playerData?.email : playerData?.licence?.email ? playerData?.licence?.email : "-"}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </AnimateHeight>
                                    </div>
                                </div>
                                <div className="border border-[#d3d3d3] dark:border-[#1b2e4b] rounded">
                                    <button
                                        type="button"
                                        className={`p-4 w-full flex items-center bg-[#1554A2] text-white`}
                                        onClick={() => togglePara2('3')}
                                    >
                                        <Contacts className='text-md mx-4' />{" "}{t('ENREGISTREMENTS')}
                                        <div className={`ltr:ml-auto rtl:mr-auto `}>
                                            {active2 == "3" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </div>
                                    </button>
                                    <div>
                                        <AnimateHeight duration={300} height={active2 === '3' ? 'auto' : 0}>
                                            <div className="p-4 text-white-dark text-[13px] border-t border-[#d3d3d3] dark:border-[#1b2e4b]">
                                                <ul className={`grid ${isMobile ? "grid-cols-1 gap-2" : "grid-cols-2 gap-4"}`}>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("ROLE")} :</span>
                                                        <span>{playerData?.role ? playerData?.role : playerData?.licence?.role ? playerData?.licence?.role : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("LEVEL")} :</span>
                                                        <span>{playerData?.niveau ? playerData?.niveau : playerData?.licence?.niveau ? playerData?.licence?.niveau : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("SPORT")} :</span>
                                                        <span>{playerData?.sport ? playerData?.sport : playerData?.licence?.sport ? playerData?.licence?.sport : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("AGE_CLASSIFICATION")} :</span>
                                                        <span>{playerData?.cateAge ? playerData?.cateAge : playerData?.licence?.cateAge ? playerData?.licence?.cateAge : "-"}</span>
                                                    </li>
                                                    <li className="flex justify-between">
                                                        <span className="text-black font-bold">{t("COMPETITIVE_DIVISIONS")} :</span>
                                                        <span>{playerData?.categoryDivisions ? playerData?.categoryDivisions : playerData?.licence?.categoryDivisions ? playerData?.licence?.categoryDivisions : "-"}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </AnimateHeight>
                                    </div>
                                </div>
                                <div className="border border-[#d3d3d3] dark:border-[#1b2e4b] rounded">
                                    <button
                                        type="button"
                                        className={`p-4 w-full flex items-center bg-[#1554A2] text-white`}
                                        onClick={() => togglePara2('4')}
                                    >
                                        <FolderShared className="text-md mx-4" />{" "}{t('DOCUMENTS')}
                                        <div className={`ltr:ml-auto rtl:mr-auto `}>
                                            {active2 == "4" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        </div>
                                    </button>
                                    <div>
                                        <AnimateHeight duration={300} height={active2 === '4' ? 'auto' : 0}>
                                            <div className="p-4 text-white-dark text-[13px] border-t border-[#d3d3d3] dark:border-[#1b2e4b]">
                                                <ul className="grid grid-cols-1 gap-1">
                                                    <li className="flex flex-wrap justify-between items-startr">
                                                        <span className="text-black font-bold">{t("PICTURE_ID")} :</span>
                                                        <div className="relative">
                                                            {renderFileContent(playerData?.photoIdentite || playerData?.licence?.photoIdentite)}
                                                            {/* <img
                                                                src={getImageUrl(playerData?.photoIdentite || playerData?.licence?.photoIdentite)}
                                                                alt=""
                                                                className="w-72 h-w-72"
                                                            /> */}

                                                            {/* <button
                                                                onClick={() => saveAs(getImageUrl(playerData?.photoIdentite || playerData?.licence?.photoIdentite), "PROBLCORPS.png")}
                                                                className="ml-4 absolute top-0 right-0 p-2 bg-blue-500 text-white rounded"
                                                            >
                                                                <DownloadIcon />
                                                            </button> */}
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-wrap justify-between items-start">
                                                        <span className="text-black font-bold">{t("CERTIFICATION_MEDICAL")} :</span>
                                                        <div className='relative'>
                                                            {renderFileContent(playerData?.certificationMedical || playerData?.licence?.certificationMedical)}
                                                            {/* <img
                                                                src={getImageUrl(playerData?.certificationMedical || playerData?.licence?.certificationMedical)}
                                                                alt=""
                                                                className="w-72 h-w-72"
                                                            /> */}
                                                            {/* <button
                                                                onClick={() => handleDownload(getImageUrl(playerData?.photoProfil || playerData?.licence?.photoProfil), splitUrl(playerData?.photoProfil || playerData?.licence?.photoProfil).fileName)}
                                                                className="ml-4 absolute top-0 right-0 p-2 bg-blue-500 text-white rounded"
                                                            >
                                                                <DownloadIcon />
                                                            </button> */}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </AnimateHeight>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
