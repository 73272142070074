// export const countriesData = [
//   { label: "Afghanistan", value: "Afghanistan" },
//   { label: "Albania", value: "Albania" },
//   { label: "Algeria", value: "Algeria" },
//   { label: "Andorra", value: "Andorra" },
//   { label: "Angola", value: "Angola" },
//   { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
//   { label: "Argentina", value: "Argentina" },
//   { label: "Armenia", value: "Armenia" },
//   { label: "Australia", value: "Australia" },
//   { label: "Austria", value: "Austria" },
//   { label: "Azerbaijan", value: "Azerbaijan" },
//   { label: "Bahamas", value: "Bahamas" },
//   { label: "Bahrain", value: "Bahrain" },
//   { label: "Bangladesh", value: "Bangladesh" },
//   { label: "Barbados", value: "Barbados" },
//   { label: "Belarus", value: "Belarus" },
//   { label: "Belgium", value: "Belgium" },
//   { label: "Belize", value: "Belize" },
//   { label: "Benin", value: "Benin" },
//   { label: "Bhutan", value: "Bhutan" },
//   { label: "Bolivia", value: "Bolivia" },
//   { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
//   { label: "Botswana", value: "Botswana" },
//   { label: "Brazil", value: "Brazil" },
//   { label: "Brunei", value: "Brunei" },
//   { label: "Bulgaria", value: "Bulgaria" },
//   { label: "Burkina Faso", value: "Burkina Faso" },
//   { label: "Burundi", value: "Burundi" },
//   { label: "Cabo Verde", value: "Cabo Verde" },
//   { label: "Cambodia", value: "Cambodia" },
//   { label: "Cameroon", value: "Cameroon" },
//   { label: "Canada", value: "Canada" },
//   { label: "Central African Republic", value: "Central African Republic" },
//   { label: "Chad", value: "Chad" },
//   { label: "Chile", value: "Chile" },
//   { label: "China", value: "China" },
//   { label: "Colombia", value: "Colombia" },
//   { label: "Comoros", value: "Comoros" },
//   { label: "Congo", value: "Congo" },
//   { label: "Costa Rica", value: "Costa Rica" },
//   { label: "Croatia", value: "Croatia" },
//   { label: "Cuba", value: "Cuba" },
//   { label: "Cyprus", value: "Cyprus" },
//   { label: "Czechia", value: "Czechia" },
//   { label: "Denmark", value: "Denmark" },
//   { label: "Djibouti", value: "Djibouti" },
//   { label: "Dominica", value: "Dominica" },
//   { label: "Dominican Republic", value: "Dominican Republic" },
//   { label: "Ecuador", value: "Ecuador" },
//   { label: "Egypt", value: "Egypt" },
//   { label: "El Salvador", value: "El Salvador" },
//   { label: "Equatorial Guinea", value: "Equatorial Guinea" },
//   { label: "Eritrea", value: "Eritrea" },
//   { label: "Estonia", value: "Estonia" },
//   { label: "Eswatini", value: "Eswatini" },
//   { label: "Ethiopia", value: "Ethiopia" },
//   { label: "Fiji", value: "Fiji" },
//   { label: "Finland", value: "Finland" },
//   { label: "France", value: "France" },
//   { label: "Gabon", value: "Gabon" },
//   { label: "Gambia", value: "Gambia" },
//   { label: "Georgia", value: "Georgia" },
//   { label: "Germany", value: "Germany" },
//   { label: "Ghana", value: "Ghana" },
//   { label: "Greece", value: "Greece" },
//   { label: "Grenada", value: "Grenada" },
//   { label: "Guatemala", value: "Guatemala" },
//   { label: "Guinea", value: "Guinea" },
//   { label: "Guinea-Bissau", value: "Guinea-Bissau" },
//   { label: "Guyana", value: "Guyana" },
//   { label: "Haiti", value: "Haiti" },
//   { label: "Honduras", value: "Honduras" },
//   { label: "Hungary", value: "Hungary" },
//   { label: "Iceland", value: "Iceland" },
//   { label: "India", value: "India" },
//   { label: "Indonesia", value: "Indonesia" },
//   { label: "Iran", value: "Iran" },
//   { label: "Iraq", value: "Iraq" },
//   { label: "Ireland", value: "Ireland" },
//   { label: "Italy", value: "Italy" },
//   { label: "Jamaica", value: "Jamaica" },
//   { label: "Japan", value: "Japan" },
//   { label: "Jordan", value: "Jordan" },
//   { label: "Kazakhstan", value: "Kazakhstan" },
//   { label: "Kenya", value: "Kenya" },
//   { label: "Kiribati", value: "Kiribati" },
//   { label: "Korea, North", value: "Korea, North" },
//   { label: "Korea, South", value: "Korea, South" },
//   { label: "Kosovo", value: "Kosovo" },
//   { label: "Kuwait", value: "Kuwait" },
//   { label: "Kyrgyzstan", value: "Kyrgyzstan" },
//   { label: "Laos", value: "Laos" },
//   { label: "Latvia", value: "Latvia" },
//   { label: "Lebanon", value: "Lebanon" },
//   { label: "Lesotho", value: "Lesotho" },
//   { label: "Liberia", value: "Liberia" },
//   { label: "Libya", value: "Libya" },
//   { label: "Liechtenstein", value: "Liechtenstein" },
//   { label: "Lithuania", value: "Lithuania" },
//   { label: "Luxembourg", value: "Luxembourg" },
//   { label: "Madagascar", value: "Madagascar" },
//   { label: "Malawi", value: "Malawi" },
//   { label: "Malaysia", value: "Malaysia" },
//   { label: "Maldives", value: "Maldives" },
//   { label: "Mali", value: "Mali" },
//   { label: "Malta", value: "Malta" },
//   { label: "Marshall Islands", value: "Marshall Islands" },
//   { label: "Mauritania", value: "Mauritania" },
//   { label: "Mauritius", value: "Mauritius" },
//   { label: "Mexico", value: "Mexico" },
//   { label: "Micronesia", value: "Micronesia" },
//   { label: "Moldova", value: "Moldova" },
//   { label: "Monaco", value: "Monaco" },
//   { label: "Mongolia", value: "Mongolia" },
//   { label: "Montenegro", value: "Montenegro" },
//   { label: "Morocco", value: "Morocco" },
//   { label: "Mozambique", value: "Mozambique" },
//   { label: "Myanmar", value: "Myanmar" },
//   { label: "Namibia", value: "Namibia" },
//   { label: "Nauru", value: "Nauru" },
//   { label: "Nepal", value: "Nepal" },
//   { label: "Netherlands", value: "Netherlands" },
//   { label: "New Zealand", value: "New Zealand" },
//   { label: "Nicaragua", value: "Nicaragua" },
//   { label: "Niger", value: "Niger" },
//   { label: "Nigeria", value: "Nigeria" },
//   { label: "North Macedonia", value: "North Macedonia" },
//   { label: "Norway", value: "Norway" },
//   { label: "Oman", value: "Oman" },
//   { label: "Pakistan", value: "Pakistan" },
//   { label: "Palau", value: "Palau" },
//   { label: "Palestine", value: "Palestine" },
//   { label: "Panama", value: "Panama" },
//   { label: "Papua New Guinea", value: "Papua New Guinea" },
//   { label: "Paraguay", value: "Paraguay" },
//   { label: "Peru", value: "Peru" },
//   { label: "Philippines", value: "Philippines" },
//   { label: "Poland", value: "Poland" },
//   { label: "Portugal", value: "Portugal" },
//   { label: "Qatar", value: "Qatar" },
//   { label: "Romania", value: "Romania" },
//   { label: "Russia", value: "Russia" },
//   { label: "Rwanda", value: "Rwanda" },
//   { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
//   { label: "Saint Lucia", value: "Saint Lucia" },
//   {
//     label: "Saint Vincent and the Grenadines",
//     value: "Saint Vincent and the Grenadines",
//   },
//   { label: "Samoa", value: "Samoa" },
//   { label: "San Marino", value: "San Marino" },
//   { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
//   { label: "Saudi Arabia", value: "Saudi Arabia" },
//   { label: "Senegal", value: "Senegal" },
//   { label: "Serbia", value: "Serbia" },
//   { label: "Seychelles", value: "Seychelles" },
//   { label: "Sierra Leone", value: "Sierra Leone" },
//   { label: "Singapore", value: "Singapore" },
//   { label: "Slovakia", value: "Slovakia" },
//   { label: "Slovenia", value: "Slovenia" },
//   { label: "Solomon Islands", value: "Solomon Islands" },
//   { label: "Somalia", value: "Somalia" },
//   { label: "South Africa", value: "South Africa" },
//   { label: "South Sudan", value: "South Sudan" },
//   { label: "Spain", value: "Spain" },
//   { label: "Sri Lanka", value: "Sri Lanka" },
//   { label: "Sudan", value: "Sudan" },
//   { label: "Suriname", value: "Suriname" },
//   { label: "Sweden", value: "Sweden" },
//   { label: "Switzerland", value: "Switzerland" },
//   { label: "Syria", value: "Syria" },
//   { label: "Taiwan", value: "Taiwan" },
//   { label: "Tajikistan", value: "Tajikistan" },
//   { label: "Tanzania", value: "Tanzania" },
//   { label: "Thailand", value: "Thailand" },
//   { label: "Timor-Leste", value: "Timor-Leste" },
//   { label: "Togo", value: "Togo" },
//   { label: "Tonga", value: "Tonga" },
//   { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
//   { label: "Tunisia", value: "Tunisia" },
//   { label: "Turkey", value: "Turkey" },
//   { label: "Turkmenistan", value: "Turkmenistan" },
//   { label: "Tuvalu", value: "Tuvalu" },
//   { label: "Uganda", value: "Uganda" },
//   { label: "Ukraine", value: "Ukraine" },
//   { label: "United Arab Emirates", value: "United Arab Emirates" },
//   { label: "United Kingdom", value: "United Kingdom" },
//   { label: "United States", value: "United States" },
//   { label: "Uruguay", value: "Uruguay" },
//   { label: "Uzbekistan", value: "Uzbekistan" },
//   { label: "Vanuatu", value: "Vanuatu" },
//   { label: "Vatican City", value: "Vatican City" },
//   { label: "Venezuela", value: "Venezuela" },
//   { label: "Vietnam", value: "Vietnam" },
//   { label: "Yemen", value: "Yemen" },
//   { label: "Zambia", value: "Zambia" },
//   { label: "Zimbabwe", value: "Zimbabwe" },
// ];

export const countriesData = [
  { label: "AFGHANISTAN", value: "AFGHANISTAN" },
  { label: "ALBANIA", value: "ALBANIA" },
  { label: "ALGERIA", value: "ALGERIA" },
  { label: "ANDORRA", value: "ANDORRA" },
  { label: "ANGOLA", value: "ANGOLA" },
  { label: "ANTIGUA AND BARBUDA", value: "ANTIGUA AND BARBUDA" },
  { label: "ARGENTINA", value: "ARGENTINA" },
  { label: "ARMENIA", value: "ARMENIA" },
  { label: "AUSTRALIA", value: "AUSTRALIA" },
  { label: "AUSTRIA", value: "AUSTRIA" },
  { label: "AZERBAIJAN", value: "AZERBAIJAN" },
  { label: "BAHAMAS", value: "BAHAMAS" },
  { label: "BAHRAIN", value: "BAHRAIN" },
  { label: "BANGLADESH", value: "BANGLADESH" },
  { label: "BARBADOS", value: "BARBADOS" },
  { label: "BELARUS", value: "BELARUS" },
  { label: "BELGIUM", value: "BELGIUM" },
  { label: "BELIZE", value: "BELIZE" },
  { label: "BENIN", value: "BENIN" },
  { label: "BHUTAN", value: "BHUTAN" },
  { label: "BOLIVIA", value: "BOLIVIA" },
  { label: "BOSNIA AND HERZEGOVINA", value: "BOSNIA AND HERZEGOVINA" },
  { label: "BOTSWANA", value: "BOTSWANA" },
  { label: "BRAZIL", value: "BRAZIL" },
  { label: "BRUNEI", value: "BRUNEI" },
  { label: "BULGARIA", value: "BULGARIA" },
  { label: "BURKINA FASO", value: "BURKINA FASO" },
  { label: "BURUNDI", value: "BURUNDI" },
  { label: "CABO VERDE", value: "CABO VERDE" },
  { label: "CAMBODIA", value: "CAMBODIA" },
  { label: "CAMEROON", value: "CAMEROON" },
  { label: "CANADA", value: "CANADA" },
  { label: "CENTRAL AFRICAN REPUBLIC", value: "CENTRAL AFRICAN REPUBLIC" },
  { label: "CHAD", value: "CHAD" },
  { label: "CHILE", value: "CHILE" },
  { label: "CHINA", value: "CHINA" },
  { label: "COLOMBIA", value: "COLOMBIA" },
  { label: "COMOROS", value: "COMOROS" },
  { label: "CONGO", value: "CONGO" },
  { label: "COSTA RICA", value: "COSTA RICA" },
  { label: "CROATIA", value: "CROATIA" },
  { label: "CUBA", value: "CUBA" },
  { label: "CYPRUS", value: "CYPRUS" },
  { label: "CZECHIA", value: "CZECHIA" },
  { label: "DENMARK", value: "DENMARK" },
  { label: "DJIBOUTI", value: "DJIBOUTI" },
  { label: "DOMINICA", value: "DOMINICA" },
  { label: "DOMINICAN REPUBLIC", value: "DOMINICAN REPUBLIC" },
  { label: "ECUADOR", value: "ECUADOR" },
  { label: "EGYPT", value: "EGYPT" },
  { label: "EL SALVADOR", value: "EL SALVADOR" },
  { label: "EQUATORIAL GUINEA", value: "EQUATORIAL GUINEA" },
  { label: "ERITREA", value: "ERITREA" },
  { label: "ESTONIA", value: "ESTONIA" },
  { label: "ESWATINI", value: "ESWATINI" },
  { label: "ETHIOPIA", value: "ETHIOPIA" },
  { label: "FIJI", value: "FIJI" },
  { label: "FINLAND", value: "FINLAND" },
  { label: "FRANCE", value: "FRANCE" },
  { label: "GABON", value: "GABON" },
  { label: "GAMBIA", value: "GAMBIA" },
  { label: "GEORGIA", value: "GEORGIA" },
  { label: "GERMANY", value: "GERMANY" },
  { label: "GHANA", value: "GHANA" },
  { label: "GREECE", value: "GREECE" },
  { label: "GRENADA", value: "GRENADA" },
  { label: "GUATEMALA", value: "GUATEMALA" },
  { label: "GUINEA", value: "GUINEA" },
  { label: "GUINEA-BISSAU", value: "GUINEA-BISSAU" },
  { label: "GUYANA", value: "GUYANA" },
  { label: "HAITI", value: "HAITI" },
  { label: "HONDURAS", value: "HONDURAS" },
  { label: "HUNGARY", value: "HUNGARY" },
  { label: "ICELAND", value: "ICELAND" },
  { label: "INDIA", value: "INDIA" },
  { label: "INDONESIA", value: "INDONESIA" },
  { label: "IRAN", value: "IRAN" },
  { label: "IRAQ", value: "IRAQ" },
  { label: "IRELAND", value: "IRELAND" },
  { label: "ITALY", value: "ITALY" },
  { label: "JAMAICA", value: "JAMAICA" },
  { label: "JAPAN", value: "JAPAN" },
  { label: "JORDAN", value: "JORDAN" },
  { label: "KAZAKHSTAN", value: "KAZAKHSTAN" },
  { label: "KENYA", value: "KENYA" },
  { label: "KIRIBATI", value: "KIRIBATI" },
  { label: "KOREA, NORTH", value: "KOREA, NORTH" },
  { label: "KOREA, SOUTH", value: "KOREA, SOUTH" },
  { label: "KOSOVO", value: "KOSOVO" },
  { label: "KUWAIT", value: "KUWAIT" },
  { label: "KYRGYZSTAN", value: "KYRGYZSTAN" },
  { label: "LAOS", value: "LAOS" },
  { label: "LATVIA", value: "LATVIA" },
  { label: "LEBANON", value: "LEBANON" },
  { label: "LESOTHO", value: "LESOTHO" },
  { label: "LIBERIA", value: "LIBERIA" },
  { label: "LIBYA", value: "LIBYA" },
  { label: "LIECHTENSTEIN", value: "LIECHTENSTEIN" },
  { label: "LITHUANIA", value: "LITHUANIA" },
  { label: "LUXEMBOURG", value: "LUXEMBOURG" },
  { label: "MADAGASCAR", value: "MADAGASCAR" },
  { label: "MALAWI", value: "MALAWI" },
  { label: "MALAYSIA", value: "MALAYSIA" },
  { label: "MALDIVES", value: "MALDIVES" },
  { label: "MALI", value: "MALI" },
  { label: "MALTA", value: "MALTA" },
  { label: "MARSHALL ISLANDS", value: "MARSHALL ISLANDS" },
  { label: "MAURITANIA", value: "MAURITANIA" },
  { label: "MAURITIUS", value: "MAURITIUS" },
  { label: "MEXICO", value: "MEXICO" },
  { label: "MICRONESIA", value: "MICRONESIA" },
  { label: "MOLDOVA", value: "MOLDOVA" },
  { label: "MONACO", value: "MONACO" },
  { label: "MONGOLIA", value: "MONGOLIA" },
  { label: "MONTENEGRO", value: "MONTENEGRO" },
  { label: "MOROCCO", value: "MOROCCO" },
  { label: "MOZAMBIQUE", value: "MOZAMBIQUE" },
  { label: "MYANMAR", value: "MYANMAR" },
  { label: "NAMIBIA", value: "NAMIBIA" },
  { label: "NAURU", value: "NAURU" },
  { label: "NEPAL", value: "NEPAL" },
  { label: "NETHERLANDS", value: "NETHERLANDS" },
  { label: "NEW ZEALAND", value: "NEW ZEALAND" },
  { label: "NICARAGUA", value: "NICARAGUA" },
  { label: "NIGER", value: "NIGER" },
  { label: "NIGERIA", value: "NIGERIA" },
  { label: "NORTH MACEDONIA", value: "NORTH MACEDONIA" },
  { label: "NORWAY", value: "NORWAY" },
  { label: "OMAN", value: "OMAN" },
  { label: "PAKISTAN", value: "PAKISTAN" },
  { label: "PALAU", value: "PALAU" },
  { label: "PALESTINE", value: "PALESTINE" },
  { label: "PANAMA", value: "PANAMA" },
  { label: "PAPUA NEW GUINEA", value: "PAPUA NEW GUINEA" },
  { label: "PARAGUAY", value: "PARAGUAY" },
  { label: "PERU", value: "PERU" },
  { label: "PHILIPPINES", value: "PHILIPPINES" },
  { label: "POLAND", value: "POLAND" },
  { label: "PORTUGAL", value: "PORTUGAL" },
  { label: "QATAR", value: "QATAR" },
  { label: "ROMANIA", value: "ROMANIA" },
  { label: "RUSSIA", value: "RUSSIA" },
  { label: "RWANDA", value: "RWANDA" },
  { label: "SAINT KITTS AND NEVIS", value: "SAINT KITTS AND NEVIS" },
  { label: "SAINT LUCIA", value: "SAINT LUCIA" },
  {
    label: "SAINT VINCENT AND THE GRENADINES",
    value: "SAINT VINCENT AND THE GRENADINES",
  },
  { label: "SAMOA", value: "SAMOA" },
  { label: "SAN MARINO", value: "SAN MARINO" },
  { label: "SAO TOME AND PRINCIPE", value: "SAO TOME AND PRINCIPE" },
  { label: "SAUDI ARABIA", value: "SAUDI ARABIA" },
  { label: "SENEGAL", value: "SENEGAL" },
  { label: "SERBIA", value: "SERBIA" },
  { label: "SEYCHELLES", value: "SEYCHELLES" },
  { label: "SIERRA LEONE", value: "SIERRA LEONE" },
  { label: "SINGAPORE", value: "SINGAPORE" },
  { label: "SLOVAKIA", value: "SLOVAKIA" },
  { label: "SLOVENIA", value: "SLOVENIA" },
  { label: "SOLOMON ISLANDS", value: "SOLOMON ISLANDS" },
  { label: "SOMALIA", value: "SOMALIA" },
  { label: "SOUTH AFRICA", value: "SOUTH AFRICA" },
  { label: "SOUTH SUDAN", value: "SOUTH SUDAN" },
  { label: "SPAIN", value: "SPAIN" },
  { label: "SRI LANKA", value: "SRI LANKA" },
  { label: "SUDAN", value: "SUDAN" },
  { label: "SURINAME", value: "SURINAME" },
  { label: "SWEDEN", value: "SWEDEN" },
  { label: "SWITZERLAND", value: "SWITZERLAND" },
  { label: "SYRIA", value: "SYRIA" },
  { label: "TAIWAN", value: "TAIWAN" },
  { label: "TAJIKISTAN", value: "TAJIKISTAN" },
  { label: "TANZANIA", value: "TANZANIA" },
  { label: "THAILAND", value: "THAILAND" },
  { label: "TIMOR-LESTE", value: "TIMOR-LESTE" },
  { label: "TOGO", value: "TOGO" },
  { label: "TONGA", value: "TONGA" },
  { label: "TRINIDAD AND TOBAGO", value: "TRINIDAD AND TOBAGO" },
  { label: "TUNISIA", value: "TUNISIA" },
  { label: "TURKEY", value: "TURKEY" },
  { label: "TURKMENISTAN", value: "TURKMENISTAN" },
  { label: "TUVALU", value: "TUVALU" },
  { label: "UGANDA", value: "UGANDA" },
  { label: "UKRAINE", value: "UKRAINE" },
  { label: "UNITED ARAB EMIRATES", value: "UNITED ARAB EMIRATES" },
  { label: "UNITED KINGDOM", value: "UNITED KINGDOM" },
  { label: "UNITED STATES", value: "UNITED STATES" },
  { label: "URUGUAY", value: "URUGUAY" },
  { label: "UZBEKISTAN", value: "UZBEKISTAN" },
  { label: "VANUATU", value: "VANUATU" },
  { label: "VATICAN CITY", value: "VATICAN CITY" },
  { label: "VENEZUELA", value: "VENEZUELA" },
  { label: "VIETNAM", value: "VIETNAM" },
  { label: "YEMEN", value: "YEMEN" },
  { label: "ZAMBIA", value: "ZAMBIA" },
  { label: "ZIMBABWE", value: "ZIMBABWE" },
];

export const sexeData = [
  { value: "HOMME", label: "HOMME" },
  { value: "FEMME", label: "FEMME" },
];

// export const rolesData = [
//   { value: "Joueur", label: "Joueur" },
//   {
//     value: "Directeur technique/entraineur",
//     label: "Directeur technique/entraineur",
//   },
//   { value: "Administrateur", label: "Administrateur" },
// ];

export const rolesData = [
  { value: "JOUER", label: "JOUER" },
  {
    value: "DIRECTEUR_TECHNIQUE_ENTRAINEUR",
    label: "DIRECTEUR_TECHNIQUE_ENTRAINEUR",
  },
  { value: "ADMINISTRATEUR", label: "ADMINISTRATEUR" },
];

export const sportsData = [{ value: "FOOTBALL", label: "FOOTBALL" }];
// export const sportsData = [{ value: "Football", label: "Football" }];
export const divisionsData = [
  // { value: "U8", label: "U8" },
  // { value: "U9", label: "U9" },
  // { value: "U10", label: "U10" },
  // { value: "U11", label: "U11" },
  // { value: "U12", label: "U12" },
  { value: "U13", label: "U13" },
  // { value: "U14", label: "U14" },
  { value: "U15", label: "U15" },
  // { value: "U16", label: "U16" },
  { value: "U17", label: "U17" },
  // { value: "U18", label: "U18" },
  // { value: "U19", label: "U19" },
  // { value: "U20", label: "U20" },
  // { value: "U21", label: "U21" },
  // { value: "U22", label: "U22" },
  // { value: "U23", label: "U23" },
  // { value: "U24", label: "U24" },
  // { value: "Seniors", label: "Seniors" },
];
export const classificationData = [
  { value: "ADULTE", label: "ADULTE" },
  { value: "MINEUR", label: "MINEUR" },
];
export const niveauData = [
  { value: "AMADOR", label: "AMADOR" },
  { value: "AMADOR_SEM_CONTRATO", label: "AMADOR_SEM_CONTRATO" },
  { value: "PROFISSIONAL", label: "PROFISSIONAL" },
];

export const roundData = [
  { value: "J1", label: "J1" },
  { value: "J2", label: "J2" },
  { value: "J3", label: "J3" },
  { value: "J4", label: "J4" },
  { value: "J5", label: "J5" },
  { value: "J6", label: "J6" },
  { value: "J7", label: "J7" },
  { value: "J8", label: "J8" },
  { value: "J9", label: "J9" },
  { value: "J10", label: "J10" },
  { value: "J11", label: "J11" },
  { value: "J12", label: "J12" },
  { value: "J13", label: "J13" },
  { value: "J14", label: "J14" },
  { value: "J15", label: "J15" },
  { value: "J16", label: "J16" },
  { value: "J17", label: "J17" },
  { value: "J18", label: "J18" },
  { value: "J19", label: "J19" },
  { value: "J20", label: "J20" },
  { value: "J21", label: "J21" },
  { value: "J22", label: "J22" },
  { value: "J23", label: "J23" },
  { value: "J24", label: "J24" },
  { value: "J25", label: "J25" },
  { value: "J26", label: "J26" },
  { value: "J27", label: "J27" },
  { value: "J28", label: "J28" },
  { value: "J29", label: "J29" },
  { value: "J30", label: "J30" },
];

export const customSingleValue = ({ data, innerRef, innerProps }) => (
  <div ref={innerRef} {...innerProps} className="flex items-center">
    <img src={data.logo} alt={data.label} className="w-6 h-6 mr-2" />
    <span>{data.label}</span>
  </div>
);

export const customOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center p-2 cursor-pointer"
    >
      <img src={data.logo} alt={data.label} className="w-6 h-6 mr-2" />
      <span>{data.label}</span>
    </div>
  );
};
