import React, { useContext, useEffect, useState } from "react";
import {
  formation_3142,
  formation_3412,
  formation_3421,
  formation_41212,
  formation_4222,
  formation_451,
  formation_532,
  formation_541,
  formation_433,
  formation_442,
  formation_4321,
  formation_4312,
  formation_424,
  formation_4231,
  formation_4213,
  formation_4141,
  formation_4132,
  formation_4123,
  formation_343,
  formation_352,
  formation_3511,
  formationA8_331,
  formationA8_232,
  formationA8_322,
  formationA8_241,
  formationA8_313,
} from "../../utils/formations";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchTeam,
  updateComposition,
} from "../../redux/middleware/matchThunks";
import { AuthContext } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { updatePlayerInfo } from "../../redux/actions/matchActions";

export default function Formation({
  setEncadrement,
  setPlayers,
  players,
  subs,
  setSubs,
  setSelectedPosition,
  selectedPosition,
  setValidateCompUpdate,
  encadrement,
  showFilterOptions,
  match,
  equipeId,
  setFormationSaved,
  formationSaved,
  defaultFormation,
  isMobile,
  setOpenMobileSelectPlayers,
}) {
  const { t } = useTranslation();
  const [selectedFormation, setSelectedFormation] = useState(
    defaultFormation || "4-3-3"
  );
  const [selectedFormatMatch, setSelectedFormatMatch] = useState("A11");
  const team = useSelector((state) => state.matchReducer);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (defaultFormation) {
      setSelectedFormation(defaultFormation);
    }
  }, [defaultFormation]);

  useEffect(() => {
    if (team?.composition) {
      setSelectedFormation(team?.composition.formation);
    }
  }, [team?.composition]);

  const handleFormationChange = (event) => {
    setSelectedFormation(event.target.value);
    setPlayers([]);
  };

  const toggleCaptain = (index) => {
    setPlayers((prevPlayers) => {
      return prevPlayers.map((player, i) => {
        if (i === index) {
          return { ...player, capitain: !player?.capitain };
        } else if (player?.capitain) {
          return { ...player, capitain: false };
        } else {
          return player;
        }
      });
    });
  };

  const formationsA11 = [
    { label: "3-1-4-2", value: formation_3142 },
    { label: "3-4-1-2", value: formation_3412 },
    { label: "3-4-2-1", value: formation_3421 },
    { label: "4-1-2-1-2", value: formation_41212 },
    { label: "4-2-2-2", value: formation_4222 },
    { label: "4-5-1", value: formation_451 },
    { label: "5-3-2", value: formation_532 },
    { label: "5-4-1", value: formation_541 },
    { label: "4-3-3", value: formation_433 },
    { label: "4-4-2", value: formation_442 },
    { label: "4-3-2-1", value: formation_4321 },
    { label: "4-3-1-2", value: formation_4312 },
    { label: "4-2-4", value: formation_424 },
    { label: "4-2-3-1", value: formation_4231 },
    { label: "4-2-1-3", value: formation_4213 },
    { label: "4-1-4-1", value: formation_4141 },
    { label: "4-1-3-2", value: formation_4132 },
    { label: "4-1-2-3", value: formation_4123 },
    { label: "3-4-3", value: formation_343 },
    { label: "3-5-2", value: formation_352 },
    { label: "3-5-1-1", value: formation_3511 },
  ];
  const formationsA8 = [
    { label: "3-3-1", value: formationA8_331 },
    { label: "2-3-2", value: formationA8_232 },
    { label: "3-2-2", value: formationA8_322 },
    { label: "2-4-1", value: formationA8_241 },
    { label: "3-1-3", value: formationA8_313 },
  ];

  const formationDispo = [
    { label: "A 8", value: "A8" },
    { label: "A 11", value: "A11" },
  ];

  const selectedFormationValue =
    formationsA11.find((form) => form.label === selectedFormation)?.value ||
    formationsA8.find((form) => form.label === selectedFormation)?.value;

  const handleRemovePlayer = (index) => {
    setPlayers((prevPlayers) => {
      const updatedPlayers = [...prevPlayers];
      updatedPlayers[index] = null;
      return updatedPlayers;
    });
  };

  const renderPlayers = () => {
    return selectedFormationValue?.map((position, index) => {
      const player = players[index];
      const { top, left, post } = position;
      const isSelected = selectedPosition === index;
      const handleClick = () => {
        if (isSelected) {
          if (isMobile) {
            setSelectedPosition(null);
            setOpenMobileSelectPlayers(false);
            return;
          }
          setSelectedPosition(null);
        } else {
          if (isMobile) {
            setSelectedPosition(index);
            setOpenMobileSelectPlayers(true);
            return;
          }
          setSelectedPosition(index);
        }
      };

      return (
        <div
          key={index}
          style={{ position: "absolute", top, left }}
          className={`flex flex-col items-center cursor-pointer ${
            isSelected ? "rounded bg-blue-500" : ""
          }`}
          onClick={() => (showFilterOptions ? handleClick() : undefined)}
        >
          {player ? (
            <>
              <div className="relative">
                {player?.capitain || player?.capitain == 1 ? (
                  <div
                    className="absolute top-0 left-0 bg-yellow-500 text-black font-bold rounded-full w-4 h-4 flex items-center justify-center text-xs"
                    style={{ zIndex: 10 }}
                  >
                    C
                  </div>
                ) : null}
                {player?.shirtNmbr ? (
                  <div
                    className="absolute text-white bg-black p-3 font-bold rounded-full w-4 h-4 flex items-center justify-center text-sm"
                    style={{ zIndex: 10, bottom: 0, right: -10 }}
                  >
                    {player?.shirtNmbr}
                  </div>
                ) : null}
                <Avatar
                  alt={player.nom}
                  src={player.image}
                  className="w-10 h-10"
                />
              </div>
              <span className="text-sm text-white max-w-20 truncate">
                {post}
              </span>
            </>
          ) : (
            <div className="text-center">
              <img
                src="../../../assets/images/tshirt.png"
                alt="T-shirt"
                className="w-10 h-10 rounded-full bg-white p-1"
              />
              <span className="text-sm text-white">{post}</span>
            </div>
          )}
        </div>
      );
    });
  };

  const handleSave = () => {
    const dataToSave = {
      match: match?.id,
      equipe: equipeId,
      formation: selectedFormation,
      players: players
        ?.map((player, index) => ({
          joueur_id: player?.id,
          position_index: index,
          post: selectedFormationValue[index]?.post,
          capitain: player?.capitain || false,
          shirtNmbr: player?.shirtNmbr,
        }))
        ?.filter((player) => player?.joueur_id !== undefined),
      replacments: subs
        .filter((sub) => sub?.id !== undefined)
        .map((sub) => ({
          joueur_id: sub?.id,
          shirtNmbr: sub?.shirtNmbr,
        })),
      personnes: encadrement
        .filter((staff) => staff?.id !== undefined)
        .map((staff) => ({
          personne_id: staff?.id,
          shirtNmbr: staff?.shirtNmbr,
        })),
    };
    const dataToState = {
      match: match?.id,
      equipe: equipeId,
      formation: selectedFormation,
      ListPlayer: players
        ?.map((player, index) => {
          return {
            ...player,
            position_index: index,
            post: selectedFormationValue[index]?.post,
            capitain: player?.capitain || false,
            shirtNmbr: player?.shirtNmbr,
          };
        })
        .filter(
          (item) =>
            item.position_index !== undefined &&
            (item.nom || item.firstName || item.fullName)
        ),
      personnes: encadrement.filter((sub) => sub?.id !== undefined),
      ListReplacment: subs.filter((sub) => sub?.id !== undefined),
    };
    const subsData = subs?.map((p) => ({
      joueur_id: p?.id,
      shirtNmbr: p?.shirtNmbr,
    }));

    const playersData = players?.map((p) => ({
      joueur_id: p?.id,
      shirtNmbr: p?.shirtNmbr,
    }));

    dispatch(updatePlayerInfo([...subsData, ...playersData]));
    const variant = "success";
    enqueueSnackbar(t("LINEUP_SAVED_SUCCESS"), { variant });
    dispatch(updateComposition(dataToSave, dataToState));
    setFormationSaved(2);
    setValidateCompUpdate(false);
  };

  const handleClear = () => {
    setSelectedFormation("4-3-3");
    setPlayers([]);
    setSubs && setSubs([]);
    setEncadrement && setEncadrement([]);
  };

  useEffect(() => {
    formationSaved == 1 && handleSave();
  }, [formationSaved]);

  return (
    <>
      {showFilterOptions && (
        <div className="mt-2 bg-white rounded-lg">
          <div className="flex flex-wrap gap-2">
            <div>
              <label
                htmlFor="format"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t("FORMAT")}
              </label>
              <select
                id="format"
                value={selectedFormatMatch}
                onChange={(e) => setSelectedFormatMatch(e.target.value)}
                className="mt-1 block w-full border pl-3 py-2 text-base border-gray-800 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                {formationDispo?.map((formation, index) => (
                  <option key={index} value={formation.value}>
                    {formation.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="formation"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t("FORMATION")}
              </label>
              <select
                id="formation"
                value={selectedFormation}
                onChange={handleFormationChange}
                className="mt-1 block w-full pl-3 bg-white border py-2 text-base border-gray-800 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                {(selectedFormatMatch === "A11"
                  ? formationsA11
                  : formationsA8
                )?.map((formation, index) => (
                  <option key={index} value={formation.label}>
                    {formation.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-end space-x-4">
              {user?.role?.toLowerCase() !== "arbitre" && (
                <button
                  onClick={() => handleSave()}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {t("SAVE")}
                </button>
              )}

              <button
                onClick={handleClear}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {t("EMPTY")}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4 w-full h-full relative">
        <img src="../../../assets/images/stade.jpg" alt="Stadium" />
        {players?.length > 0 && showFilterOptions && (
          <>
            <div
              onClick={() => toggleCaptain(selectedPosition)}
              className="absolute top-2 cursor-pointer left-5 bg-yellow-500 text-black font-bold rounded px-2 py-1 flex items-center justify-center text-xs"
              style={{ zIndex: 10 }}
            >
              {t("IS_CAPITAINE")}
            </div>
            <div
              onClick={() => handleRemovePlayer(selectedPosition)}
              className="absolute top-2 font-myteam cursor-pointer right-5 bg-yellow-500 text-black font-bold rounded px-2 py-1 flex items-center justify-center text-xs"
              style={{ zIndex: 10 }}
            >
              {t("REMOVE_PLAYER")}
            </div>
          </>
        )}
        <div className="absolute top-0 left-0 w-full h-full grid grid-cols-3 grid-rows-4 gap-4">
          {renderPlayers()}
        </div>
      </div>
    </>
  );
}
