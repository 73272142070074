import React, { useContext, useState } from "react";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { addEventToState } from "../../../redux/actions/matchEventsActions";
import { useSelector } from "react-redux";
import { MobileContext } from "../../../backoffice/src/context/isMobileContext";
import ModalSelectPlayerMobile from "../../../backoffice/src/components/Modal/ModalSelectPlayerMobile";

const SubsEvent = ({
  playerOut,
  playerIn,
  setPlayerSelected,
  currentTeam,
  teamRecevant,
  teamVisiteuse,
  players,
}) => {
  const events = useSelector((state) => state.matchEventsReducer.events);
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [substitutionTime, setSubstitutionTime] = useState({
    RegularTime: 0,
    extraTime: 0,
  });
  const [openModalSelectPlayerOut, setOpenModalSelectPlayerOut] =
    useState(false);
  const [openModalSelectPlayerIn, setOpenModalSelectPlayerIn] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleValidateSubstitution = () => {
    const { RegularTime, extraTime } = substitutionTime;

    if (!playerIn || !playerOut) {
      const variant = "error";
      enqueueSnackbar(t("FILL_ALL_FIELDS"), { variant });
      return;
    }
    if (!RegularTime) {
      const variant = "error";
      enqueueSnackbar(t("SELECT_TIME"), { variant });
      return;
    }
    if (playerIn.teamId !== playerOut.teamId) {
      enqueueSnackbar(t("PLAYERS_FROM_DIFFERENT_TEAMS"), { variant: "error" });
      return;
    }

    const hasPlayerInEvent = events.some(
      (event) =>
        event.playerIn?.id === playerIn?.id && event.eventName === "subs"
    );
    const hasPlayerOutEvent = events.some(
      (event) =>
        event.playerOut?.id === playerOut?.id && event.eventName === "subs"
    );
    const hasPlayersEvent = events.some(
      (event) =>
        event.playerOut?.id === playerIn?.id && event.eventName === "subs"
    );

    if (hasPlayerInEvent || hasPlayerOutEvent || hasPlayersEvent) {
      enqueueSnackbar(t("PLAYER_ALREADY_HAS_SUBSTITUTION_EVENT"), {
        variant: "error",
      });
      return;
    }

    const isValidRegularTime =
      RegularTime === 45 ||
      RegularTime === 90 ||
      RegularTime === 105 ||
      RegularTime === 120;

    const substitutionEvent = {
      team: {
        id:
          currentTeam === "teamRecevant" ? teamRecevant?.id : teamVisiteuse?.id,
        name:
          currentTeam === "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        logo:
          currentTeam === "teamRecevant"
            ? teamRecevant?.logo
            : teamVisiteuse?.logo,
      },
      playerIn: playerIn,
      playerOut: playerOut,
      eventName: "subs",
      RegularTime,
      extraTime: isValidRegularTime ? extraTime : 0,
    };

    dispatch(addEventToState(substitutionEvent));
    setPlayerSelected([]);
    setSubstitutionTime({ RegularTime: 0, extraTime: 0 });
  };

  const handleSelectModalPlayer = (player, etat) => {
    if (etat === "out" && playerIn?.id !== player?.id) {
      setPlayerSelected([player, playerIn]);
    } else if (etat === "in" && playerOut?.id !== player?.id) {
      setPlayerSelected([playerOut, player]);
    }
  };

  const handleChange = (field, value) => {
    setSubstitutionTime((prev) => ({ ...prev, [field]: parseInt(value) }));
  };

  const handlePlayerClick = (player) => {
    setPlayerSelected((prevState) => {
      const isSelected = prevState.some((p) => p?.id === player?.id);
      if (isSelected) {
        return prevState.filter((p) => p?.id !== player?.id);
      } else if (prevState.length < 2) {
        return [...prevState, player];
      } else {
        return prevState;
      }
    });
  };

  const isExtraTimeDisabled = !(
    substitutionTime.RegularTime === 45 ||
    substitutionTime.RegularTime === 90 ||
    substitutionTime.RegularTime === 105 ||
    substitutionTime.RegularTime === 120
  );

  return (
    <div
      className={`${
        isMobile ? "p-0" : "p-6"
      } bg-white rounded-lg shadow-lg text-center border border-gray-400`}
    >
      <h2 className="text-xl font-myteam font-bold mb-4">
        {t("MAKE_SUBSTITUTION")}
      </h2>
      {openModalSelectPlayerOut ? (
        <ModalSelectPlayerMobile
          players={players}
          isOpen={openModalSelectPlayerOut}
          callback={(ply) => handleSelectModalPlayer(ply, "out")}
          onClose={() => setOpenModalSelectPlayerOut(false)}
        />
      ) : null}
      {openModalSelectPlayerIn ? (
        <ModalSelectPlayerMobile
          players={players}
          isOpen={openModalSelectPlayerIn}
          callback={(ply) => handleSelectModalPlayer(ply, "in")}
          onClose={() => setOpenModalSelectPlayerIn(false)}
        />
      ) : null}

      <div className="flex flex-wrap justify-around gap-4">
        <div className="flex justify-between mb-4">
          <div className="flex items-center space-x-4">
            <div className="text-center">
              <label
                htmlFor="playerOut"
                className="block text-sm font-myteam font-medium text-gray-700 mb-1"
              >
                {t("PLAYER_OUT")}
              </label>
              <div className="space-y-2 font-myteam">
                {playerOut && playerOut?.id !== playerIn?.id ? (
                  <>
                    {" "}
                    <Avatar
                      alt={playerOut?.fullName}
                      src={playerOut?.image}
                      className="mx-auto cursor-pointer"
                      onClick={() => handlePlayerClick(playerOut)}
                    />
                    <p>{playerOut?.fullName}</p>
                  </>
                ) : (
                  <div
                    className="flex justify-center"
                    onClick={() =>
                      isMobile ? setOpenModalSelectPlayerOut(true) : undefined
                    }
                  >
                    <img
                      src="../../../assets/images/tshirt.png"
                      alt="T-shirt"
                      className="w-10 h-10 rounded bg-white p-1"
                    />
                  </div>
                )}
              </div>
            </div>
            <img
              src="../../assets/images/subs.png"
              width={30}
              alt="Substitution"
              className="mx-4"
            />
            <div className="text-center">
              <label
                htmlFor="playerIn"
                className="block text-sm font-myteam font-medium text-gray-700 mb-1"
              >
                {t("PLAYER_IN")}
              </label>
              <div className="space-y-2">
                {playerIn && playerIn?.id !== playerOut?.id ? (
                  <>
                    <Avatar
                      alt={playerIn?.fullName}
                      src={playerIn?.image}
                      className="mx-auto cursor-pointer"
                      onClick={() => handlePlayerClick(playerIn)}
                    />
                    <p>{playerIn?.fullName}</p>
                  </>
                ) : (
                  <div
                    className="flex justify-center"
                    onClick={() =>
                      isMobile ? setOpenModalSelectPlayerIn(true) : undefined
                    }
                  >
                    <img
                      src="../../../assets/images/tshirt.png"
                      alt="T-shirt"
                      className="w-10 h-10 rounded bg-white p-1"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="substitutionMinute"
            className="block text-sm font-myteam font-medium text-gray-700 mb-1"
          >
            {t("SUBSTITUTION_MINUTE")}
          </label>

          <div className="flex items-center">
            <div className="flex w-max flex-wrap items-center rounded-lg">
              <div className="p-2">
                <input
                  type="number"
                  value={substitutionTime.RegularTime}
                  min={0}
                  max={50}
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  onChange={(e) => handleChange("RegularTime", e.target.value)}
                  className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <label>+</label>
              <div className="p-2">
                <input
                  type="number"
                  disabled={isExtraTimeDisabled}
                  value={substitutionTime.extraTime}
                  min={0}
                  max={50}
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  onChange={(e) => handleChange("extraTime", e.target.value)}
                  className="mt-1 text-center font-myteam block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={() => handleValidateSubstitution()}
        className="px-4 w-auto py-2 font-myteam bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none mb-4"
      >
        {t("VALIDATE_SUBSTITUTION")}
      </button>
    </div>
  );
};

export default SubsEvent;
